import { useState, useEffect, useMemo, useCallback } from "react";
import { useNavigate } from "react-router-dom";
import { useAddNewBookingMutation } from "./bookingsApiSlice";
import useAuth from "../../hooks/useAuth";
import { Tooltip } from "react-tooltip";
import Modal from "react-modal";

Modal.setAppElement("#root");

const NewBookingForm = ({ users, vehicles }) => {
  const [addNewBooking, { isLoading, isSuccess, isError, error }] =
    useAddNewBookingMutation();

  const navigate = useNavigate();

  const { email } = useAuth();

  // Function to find the user ID based on email
  const getUserIdFromEmail = (users, email) => {
    const foundUser = users.find((user) => user.email === email);
    return foundUser ? foundUser.id : null; // Assuming 'id' represents the user ID in the user object
  };

  // Use the function to retrieve the user ID
  const userId = getUserIdFromEmail(users, email);

  function getCurrentDateAsString() {
    const currentDate = new Date();

    // Get the year, month, and day components of the current date
    const year = currentDate.getFullYear();
    const month = String(currentDate.getMonth() + 1).padStart(2, "0"); // Add 1 to month since it's zero-based
    const day = String(currentDate.getDate()).padStart(2, "0");

    // Create the date string in the format "YYYY-MM-DD"
    const dateString = `${year}-${month}-${day}`;

    return dateString;
  }

  const currentDateAsString = getCurrentDateAsString();

  // for the order number

  const uniqueId = Math.random().toString(36).substring(2, 8);
  const currentDateNow = new Date().getTime();
  const newOrderNumber = `ORD-${currentDateNow}-${uniqueId}`;

  const [vehicle, setVehicle] = useState(""); // Initialized with an empty string
  const [dealership, setDealership] = useState(""); // Initialized with an empty string
  const [dealershipName, setDealershipName] = useState("");
  const [selectedVehicle, setSelectedVehicle] = useState("");
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [isManualBooking, setIsManualBooking] = useState(false);

  const [startTime, setStartTime] = useState("");
  const [endTime, setEndTime] = useState("");
  const [vehicleAvailability, setVehicleAvailability] = useState("");

  const [vehicleHomeDelivery, setVehicleHomeDelivery] = useState("");

  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    const vehicleId = urlParams.get("vehicleId");

    const selectedVehicle = vehicles.find(
      (vehicle) => vehicle.id === vehicleId
    );

    if (selectedVehicle) {
      setVehicle(selectedVehicle.id);
      setDealership(selectedVehicle.owner); // Assuming 'owner' property exists in the vehicle data
      // Update other state variables as needed
      setDealershipName(selectedVehicle.companyName);
      setSelectedVehicle(selectedVehicle);
      setVehicleAvailability(selectedVehicle.availability);
      setVehicleHomeDelivery(selectedVehicle.delivery.acceptHomeDelivery);
    }
  }, [vehicles]);

  // for the total price

  // useEffect(() => {
  //   const calculateTotalPrice = () => {
  //     if (duration > 0 && selectedVehicle) {
  //       return duration * selectedVehicle.pricePerDay;
  //     }
  //     return 0; // If either duration or selected vehicle is not available
  //   };

  //   const totalPrice = calculateTotalPrice();
  //   setTotalPrice(totalPrice);
  // }, [duration, selectedVehicle]);

  const [totalPrice, setTotalPrice] = useState("");

  const [pickUpLocation, setPickUpLocation] = useState("Dealership");
  const [dropOffLocation, setDropOffLocation] = useState("Dealership");

  const [pickUpLocationInDepth, setPickUpLocationInDepth] = useState({
    type: "Dealership", // Initially empty, will hold either 'Dealership' or 'Custom'
    address: {
      line1: "",
      line2: "",
      city: "",
      county: "",
      postCode: "",
    },
  });

  const [dropOffLocationInDepth, setDropOffLocationInDepth] = useState({
    type: "Dealership", // Initially empty, will hold either 'Dealership' or 'Custom'
    address: {
      line1: "",
      line2: "",
      city: "",
      county: "",
      postCode: "",
    },
  });

  const [dateOfBooking, setDateOfBooking] = useState(currentDateAsString);
  const [orderNumber, setOrderNumber] = useState(newOrderNumber);

  const [chauffeur, setChauffeur] = useState(false);
  const [description, setDescription] = useState("");
  const [mainCustomer, setMainCustomer] = useState({
    customer: userId,
    drivingLicense: {
      number: "",
      dateOfIssue: "",
      dateOfExpiry: "",
      placeOfIssue: "",
    },
    passport: {
      number: "",
      dateOfIssue: "",
      dateOfExpiry: "",
    },
    dateOfBirth: "",
    address: {
      line1: "",
      line2: "",
      city: "",
      county: "",
      postCode: "",
    },
    connections: {
      email: "",
      number: "",
    },
  });

  const [additionalDriver, setAdditionalDriver] = useState({
    customer: "",
    drivingLicense: {
      number: "",
      dateOfIssue: "",
      dateOfExpiry: "",
      placeOfIssue: "",
    },
    passport: {
      number: "",
      dateOfIssue: "",
      dateOfExpiry: "",
    },
    dateOfBirth: "",
    address: {
      line1: "",
      line2: "",
      city: "",
      county: "",
      postCode: "",
    },
    connections: {
      email: "",
      number: "",
    },
  });

  const [bookingStatus, setBookingStatus] = useState("Preparing Vehicle"); //"Preparing Vehicle", "Vehicle Ready", "Vehicle Sent Out", "Awaiting Vehicle Return", "Vehicle Fufilled and Returned",
  const [complete, setComplete] = useState(false);
  const [insuranceDetails, setInsuranceDetails] = useState({
    insuranceCompany: "",
    policyNumber: "",
    validFrom: "",
    validUntil: "",
  });
  const [beforeImages, setBeforeImages] = useState("");
  const [afterImages, setAfterImages] = useState("");
  const [chauffeurFee, setChauffeurFee] = useState(0);
  const [additionalDriverFee, setAdditionalDriverFee] = useState(0);
  const [chauffeurDetails, setChauffeurDetails] = useState("");
  const [bookingProgress, setBookingProgress] = useState("To Be Confirmed");

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isSecondaryModalOpen, setIsSecondaryModalOpen] = useState(false);

  const [isPaymentBooking, setIsPaymentBooking] = useState(false);

  const openModal = () => setIsModalOpen(true);
  const closeModal = () => setIsModalOpen(false);

  const handlePayNowClick = () => {
    setIsModalOpen(false); // Close the main modal
    setIsSecondaryModalOpen(true); // Open the secondary modal
  };

  const closeModals = () => {
    setIsModalOpen(false);
    setIsSecondaryModalOpen(false);
  };

  // useEffect(() => {
  //   const calculateTotalPrice = () => {
  //     if (duration > 0 && selectedVehicle) {
  //       // Base rental price
  //       const rentalPrice = duration * selectedVehicle.pricePerDay;

  //       // Deposit
  //       const deposit = selectedVehicle.deposit || 0;

  //       // Additional driver fee
  //       let additionalDriverFeeCalculated = 0;
  //       if (additionalDriver.customer) {
  //         // If an additional driver is selected
  //         const additionalDriverPricePerDay =
  //           selectedVehicle.additionalDriverPricePerDay || 0;
  //         additionalDriverFeeCalculated =
  //           duration * additionalDriverPricePerDay; // Calculate fee based on duration
  //         setAdditionalDriverFee(additionalDriverFeeCalculated);
  //       }

  //       // Chauffeur fee
  //       let calculatedChauffeurFee = 0;
  //       if (chauffeur) {
  //         const chauffeurPricePerDay =
  //           selectedVehicle.chauffeurPricePerDay || 0;
  //         calculatedChauffeurFee = duration * chauffeurPricePerDay; // Calculate fee based on duration
  //       }

  //       setChauffeurFee(calculatedChauffeurFee);

  //       // Total price
  //       return (
  //         rentalPrice + deposit + additionalDriverFee + calculatedChauffeurFee
  //       );
  //     }

  //     return 0; // Default to zero if conditions aren't met
  //   };

  //   const totalPrice = calculateTotalPrice(); // Calculate the total price
  //   setTotalPrice(totalPrice); // Update state with the new total price
  // }, [
  //   duration,
  //   selectedVehicle,
  //   additionalDriver,
  //   chauffeur,
  //   setTotalPrice,
  //   additionalDriverFee,
  //   chauffeurFee,
  // ]); // Add chauffeur to dependency list

  // const calculateDuration = () => {
  //   if (startDate && endDate) {
  //     const start = new Date(startDate);
  //     const end = new Date(endDate);
  //     const durationInMs = Math.abs(end - start);
  //     const millisecondsPerDay = 1000 * 60 * 60 * 24; // milliseconds in a day
  //     const durationInDays = Math.ceil(durationInMs / millisecondsPerDay);
  //     return durationInDays;
  //   }
  //   return 0; // If the dates are not both provided
  // };

  // const duration = calculateDuration();

  const getDaysBreakdown = (startDate, endDate) => {
    if (!startDate || !endDate) return { weekdays: 0, weekends: 0 };

    const start = new Date(startDate);
    const end = new Date(endDate);
    let weekdays = 0;
    let weekends = 0;

    // Clone the start date
    let current = new Date(start);

    // Iterate through each day
    while (current <= end) {
      // getDay() returns 0 for Sunday, 6 for Saturday
      const day = current.getDay();
      if (day === 0 || day === 6) {
        weekends++;
      } else {
        weekdays++;
      }
      // Move to next day
      current.setDate(current.getDate() + 1);
    }

    return { weekdays, weekends };
  };

  // const calculateDetailedPrice =
  //   (startDate, endDate, selectedVehicle) => {
  //     const duration = calculateDuration(startDate, endDate);
  //     const { weekdays, weekends } = getDaysBreakdown(startDate, endDate);

  //     // If no vehicle selected or no duration, return zero
  //     if (!selectedVehicle || duration === 0)
  //       return { total: 0, breakdown: {} };

  //     const {
  //       pricePerDay,
  //       weekendPricePerDay,
  //       weeklyPrice,
  //       monthlyPrice,
  //       deposit = 0,
  //       additionalDriverPricePerDay = 0,
  //       chauffeurPricePerDay = 0,
  //     } = selectedVehicle;

  //     // Calculate base rental price
  //     let rentalPrice = 0;
  //     let priceBreakdown = {};

  //     // Handle monthly pricing first
  //     const completeMonths = Math.floor(duration / 30);
  //     const remainingDays = duration % 30;

  //     if (completeMonths > 0) {
  //       rentalPrice += completeMonths * monthlyPrice;
  //       priceBreakdown.monthlyRate = {
  //         count: completeMonths,
  //         pricePerMonth: monthlyPrice,
  //         total: completeMonths * monthlyPrice,
  //       };
  //     }

  //     // Handle remaining days after months
  //     if (remainingDays > 0) {
  //       // Check if remaining days qualify for weekly rate
  //       const completeWeeks = Math.floor(remainingDays / 7);
  //       const daysAfterWeeks = remainingDays % 7;

  //       if (completeWeeks > 0) {
  //         rentalPrice += completeWeeks * weeklyPrice;
  //         priceBreakdown.weeklyRate = {
  //           count: completeWeeks,
  //           pricePerWeek: weeklyPrice,
  //           total: completeWeeks * weeklyPrice,
  //         };
  //       }

  //       // For the remaining days, calculate weekday/weekend rates
  //       if (daysAfterWeeks > 0) {
  //         const { weekdays, weekends } = getDaysBreakdown(
  //           new Date(startDate).setDate(
  //             new Date(startDate).getDate() +
  //               completeMonths * 30 +
  //               completeWeeks * 7
  //           ),
  //           endDate
  //         );

  //         const weekdayPrice = weekdays * pricePerDay;
  //         const weekendPrice = weekends * weekendPricePerDay;

  //         rentalPrice += weekdayPrice + weekendPrice;
  //         priceBreakdown.individualDays = {
  //           weekdays: { count: weekdays, pricePerDay, total: weekdayPrice },
  //           weekends: {
  //             count: weekends,
  //             pricePerDay: weekendPricePerDay,
  //             total: weekendPrice,
  //           },
  //         };
  //       }
  //     }

  //     return {
  //       baseRentalPrice: rentalPrice,
  //       deposit,
  //       priceBreakdown,
  //       duration,
  //       weekdays,
  //       weekends,
  //     };
  //   }
  // ;

  const calculateDetailedPrice = useCallback(
    (startDate, endDate, selectedVehicle) => {
      const calculateDuration = () => {
        if (startDate && endDate) {
          const start = new Date(startDate);
          const end = new Date(endDate);
          const durationInMs = Math.abs(end - start);
          const millisecondsPerDay = 1000 * 60 * 60 * 24; // milliseconds in a day
          const durationInDays = Math.ceil(durationInMs / millisecondsPerDay);
          return durationInDays;
        }
        return 0; // If the dates are not both provided
      };

      // const duration = calculateDuration();
      const duration = calculateDuration(startDate, endDate);
      const { weekdays, weekends } = getDaysBreakdown(startDate, endDate);

      if (!selectedVehicle || duration === 0)
        return { total: 0, breakdown: {} };

      const {
        pricePerDay,
        weekendPricePerDay,
        weeklyPrice,
        monthlyPrice,
        deposit = 0,
        additionalDriverPricePerDay = 0,
        chauffeurPricePerDay = 0,
      } = selectedVehicle;

      let rentalPrice = 0;
      let priceBreakdown = {};

      const completeMonths = Math.floor(duration / 30);
      const remainingDays = duration % 30;

      if (completeMonths > 0) {
        rentalPrice += completeMonths * monthlyPrice;
        priceBreakdown.monthlyRate = {
          count: completeMonths,
          pricePerMonth: monthlyPrice,
          total: completeMonths * monthlyPrice,
        };
      }

      if (remainingDays > 0) {
        const completeWeeks = Math.floor(remainingDays / 7);
        const daysAfterWeeks = remainingDays % 7;

        if (completeWeeks > 0) {
          rentalPrice += completeWeeks * weeklyPrice;
          priceBreakdown.weeklyRate = {
            count: completeWeeks,
            pricePerWeek: weeklyPrice,
            total: completeWeeks * weeklyPrice,
          };
        }

        if (daysAfterWeeks > 0) {
          const { weekdays, weekends } = getDaysBreakdown(
            new Date(startDate).setDate(
              new Date(startDate).getDate() +
                completeMonths * 30 +
                completeWeeks * 7
            ),
            endDate
          );

          const weekdayPrice = weekdays * pricePerDay;
          const weekendPrice = weekends * weekendPricePerDay;

          rentalPrice += weekdayPrice + weekendPrice;
          priceBreakdown.individualDays = {
            weekdays: { count: weekdays, pricePerDay, total: weekdayPrice },
            weekends: {
              count: weekends,
              pricePerDay: weekendPricePerDay,
              total: weekendPrice,
            },
          };
        }
      }

      return {
        baseRentalPrice: rentalPrice,
        deposit,
        priceBreakdown,
        duration,
        weekdays,
        weekends,
      };
    },
    []
  );

  const [priceBreakdown, setPriceBreakdown] = useState({});
  console.log(priceBreakdown);

  useEffect(() => {
    const calculateTotalPrice = () => {
      const {
        baseRentalPrice,
        deposit,
        priceBreakdown: detailedBreakdown,
        duration,
      } = calculateDetailedPrice(startDate, endDate, selectedVehicle);

      // Calculate additional driver fee if applicable
      let additionalDriverFeeCalculated = 0;
      if (additionalDriver?.customer) {
        additionalDriverFeeCalculated =
          duration * (selectedVehicle?.additionalDriverPricePerDay || 0);
        setAdditionalDriverFee(additionalDriverFeeCalculated);
      } else {
        setAdditionalDriverFee(0);
      }

      // Calculate chauffeur fee if applicable
      let calculatedChauffeurFee = 0;
      if (chauffeur) {
        calculatedChauffeurFee =
          duration * (selectedVehicle?.chauffeurPricePerDay || 0);
        setChauffeurFee(calculatedChauffeurFee);
      } else {
        setChauffeurFee(0);
      }

      // Compute the total price
      const total =
        baseRentalPrice +
        deposit +
        additionalDriverFeeCalculated +
        calculatedChauffeurFee;

      // Update the external totalPrice state
      setTotalPrice(total);

      // Update breakdown info (if you wish to display it)
      setPriceBreakdown({
        ...detailedBreakdown,
        additionalServices: {
          additionalDriver: additionalDriverFeeCalculated,
          chauffeur: calculatedChauffeurFee,
          deposit,
        },
      });
    };

    // Only calculate if startDate, endDate, and selectedVehicle are set
    if (startDate && endDate && selectedVehicle) {
      calculateTotalPrice();
    }
  }, [
    startDate,
    endDate,
    selectedVehicle,
    additionalDriver,
    chauffeur,
    calculateDetailedPrice,
  ]);

  useEffect(() => {
    if (isSuccess && !isPaymentBooking) {
      setVehicle("");
      setDealership("");
      setPickUpLocation("");
      setDropOffLocation("");
      setPickUpLocationInDepth({
        type: "",
        address: {
          line1: "",
          line2: "",
          city: "",
          county: "",
          postCode: "",
        },
      });
      setBookingProgress("To Be Confirmed");
      setIsManualBooking(false);
      setChauffeurDetails("");
      setDropOffLocationInDepth({
        type: "",
        address: {
          line1: "",
          line2: "",
          city: "",
          county: "",
          postCode: "",
        },
      });
      setAdditionalDriverFee(0);
      setStartDate("");
      setEndDate("");
      setStartTime("");
      setEndTime("");
      setDateOfBooking("");
      setOrderNumber("");
      setTotalPrice("");
      setChauffeur(false);
      setChauffeurFee(0);
      setDescription("");
      setMainCustomer({
        customer: "",
        drivingLicense: {
          number: "",
          dateOfIssue: "",
          dateOfExpiry: "",
          placeOfIssue: "",
        },
        passport: {
          number: "",
          dateOfIssue: "",
          dateOfExpiry: "",
        },
        dateOfBirth: "",
        address: {
          line1: "",
          line2: "",
          city: "",
          county: "",
          postCode: "",
        },
        connections: {
          email: "",
          number: "",
        },
      });
      setAdditionalDriver({
        customer: "",
        drivingLicense: {
          number: "",
          dateOfIssue: "",
          dateOfExpiry: "",
          placeOfIssue: "",
        },
        passport: {
          number: "",
          dateOfIssue: "",
          dateOfExpiry: "",
        },
        dateOfBirth: "",
        address: {
          line1: "",
          line2: "",
          city: "",
          county: "",
          postCode: "",
        },
        connections: {
          email: "",
          number: "",
        },
      });
      setBookingStatus("");
      setComplete(false);
      setInsuranceDetails({
        insuranceCompany: "",
        policyNumber: "",
        validFrom: "",
        validUntil: "",
      });
      setBeforeImages("");
      setAfterImages("");

      navigate("/customerdashboard/myOrders");
    }
  }, [isSuccess, navigate, isPaymentBooking]);

  const onVehicleChanged = (e) => setVehicle(e.target.value);
  const onDealershipChanged = (e) => setDealership(e.target.value);
  // const onPickUpLocationChanged = (e) => setPickUpLocation(e.target.value);
  // const onDropOffLocationChanged = (e) => setDropOffLocation(e.target.value);
  const onChauffeurDetailsChanged = (e) => setChauffeurDetails(e.target.value);

  const handlePickUpLocationChange = (e) => {
    const { name, value } = e.target;

    if (name === "type") {
      // Update the type field directly
      setPickUpLocationInDepth((prevState) => ({
        ...prevState,
        type: value,
      }));
    } else {
      // Update the nested address fields
      setPickUpLocationInDepth((prevState) => ({
        ...prevState,
        address: {
          ...prevState.address,
          [name]: value,
        },
      }));
    }
  };

  const handleDropOffLocationChange = (e) => {
    const { name, value } = e.target;

    if (name === "type") {
      // Update the type field directly
      setDropOffLocationInDepth((prevState) => ({
        ...prevState,
        type: value,
      }));
    } else {
      // Update the nested address fields
      setDropOffLocationInDepth((prevState) => ({
        ...prevState,
        address: {
          ...prevState.address,
          [name]: value,
        },
      }));
    }
  };

  const onStartDateChanged = (e) => {
    const selectedDate = e.target.value;
    // To prevent selecting a date before the current date
    const currentDate = new Date().toISOString().split("T")[0];
    if (selectedDate < currentDate) {
      // Don't update the state if selected date is before the current date
      return;
    }
    setStartDate(selectedDate);
  };
  const onEndDateChanged = (e) => {
    const selectedDate = e.target.value;
    // To prevent selecting a date before the start date
    if (selectedDate < startDate) {
      // Don't update the state if selected date is before the start date
      return;
    }
    setEndDate(selectedDate);
  };

  const onStartTimeChanged = (e) => setStartTime(e.target.value);
  const onEndTimeChanged = (e) => setEndTime(e.target.value);

  const onOrderNumberChanged = (e) => setOrderNumber(e.target.value); // we need to generate this
  const onTotalPriceChanged = (e) => setTotalPrice(e.target.value);
  const onChauffeurChanged = (e) => setChauffeur(e.target.checked);

  // For mainCustomer
  // const onMainCustomerChanged = (e) => {
  //   const { name, value } = e.target;
  //   setMainCustomer((prevMainCustomer) => ({
  //     ...prevMainCustomer,
  //     [name]: value,
  //   }));
  // };
  const onMainCustomerChanged = (e) => {
    const { name, value } = e.target;

    // Split the name into parts based on the dot (.) to handle nested fields
    const nameParts = name.split(".");

    // Create a copy of the current mainCustomer object to modify
    let updatedMainCustomer = { ...mainCustomer };

    // Use a loop to navigate through the nested structure
    let currentLevel = updatedMainCustomer;
    for (let i = 0; i < nameParts.length; i++) {
      const part = nameParts[i];
      if (i === nameParts.length - 1) {
        // If it's the last part, update the value
        currentLevel[part] = value;
      } else {
        // Otherwise, navigate deeper into the nested structure
        currentLevel = currentLevel[part];
      }
    }

    // Update the mainCustomer state with the modified object
    setMainCustomer(updatedMainCustomer);
  };

  // For additionalDriver
  // const onAdditionalDriverChanged = (e) => {
  //   const { name, value } = e.target;
  //   setAdditionalDriver((prevAdditionalDriver) => ({
  //     ...prevAdditionalDriver,
  //     [name]: value,
  //   }));
  // };
  const onAdditionalDriverChanged = (e) => {
    const { name, value } = e.target;

    // Split the name into parts based on the dot (.) to handle nested fields
    const nameParts = name.split(".");

    // Create a copy of the current additionalDriver object to modify
    let updatedAdditionalDriver = { ...additionalDriver };

    // Use a loop to navigate through the nested structure
    let currentLevel = updatedAdditionalDriver;
    for (let i = 0; i < nameParts.length; i++) {
      const part = nameParts[i];
      if (i === nameParts.length - 1) {
        // If it's the last part, update the value
        currentLevel[part] = value;
      } else {
        // Otherwise, navigate deeper into the nested structure
        currentLevel = currentLevel[part];
      }
    }

    // Update the additionalDriver state with the modified object
    setAdditionalDriver(updatedAdditionalDriver);
  };

  // const canSave =
  //   [
  //     vehicle,
  //     dealership,
  //     pickUpLocation,
  //     dropOffLocation,
  //     startDate,
  //     endDate,
  //     dateOfBooking,
  //     orderNumber,
  //     totalPrice,
  //     chauffeur,
  //     mainCustomer,
  //     complete,
  //   ].every(Boolean) && !isLoading;

  const canSave =
    [
      vehicle,
      dealership,
      // pickUpLocation,
      // dropOffLocation,
      pickUpLocationInDepth,
      dropOffLocationInDepth,
      startDate,
      endDate,
      startTime,
      endTime,
      dateOfBooking,
      orderNumber,
      totalPrice,
      chauffeur,
      isManualBooking,
      mainCustomer,
      bookingProgress,
      complete,
    ].every((item) => item !== null && item !== undefined) && !isLoading;

  const onSaveBookingClicked = async (e) => {
    e.preventDefault();
    if (canSave) {
      await addNewBooking({
        vehicle,
        dealership,
        pickUpLocation,
        dropOffLocation,
        pickUpLocationInDepth,
        dropOffLocationInDepth,
        startDate,
        endDate,
        startTime,
        endTime,
        dateOfBooking,
        orderNumber,
        totalPrice,
        isManualBooking,
        chauffeur,
        chauffeurFee,
        description,
        mainCustomer,
        additionalDriver,
        bookingStatus,
        complete,
        insuranceDetails,
        beforeImages,
        afterImages,
        additionalDriverFee,
        chauffeurDetails,
        bookingProgress,
      });
    }
  };

  // const handleBookAndPay = async () => {
  //   setIsPaymentBooking(true);

  //   const BASE_URL = "http://localhost:3500/api/bookingPayments";

  //   try {
  //     const bookingResponse = await addNewBooking(bookingData).unwrap();

  //     if (bookingResponse.success && bookingResponse.bookingId) {
  //       const response = await fetch(`${BASE_URL}/create-checkout-session`, {
  //         method: "POST",
  //         headers: {
  //           "Content-Type": "application/json",
  //         },
  //         body: JSON.stringify({
  //           bookingId: bookingResponse.bookingId,
  //         }),
  //       });

  //       const { url } = await response.json();

  //       if (url) {
  //         window.location.href = url;
  //       }
  //     }
  //   } catch (err) {
  //     console.error("Error:", err);
  //     setIsPaymentBooking(false); // Reset in case of error
  //   }
  // };

  const handleBookAndPay = async (e) => {
    e.preventDefault();
    setIsPaymentBooking(true);
    // const BASE_URL = "http://localhost:3500/api/bookingPayments";
    const BASE_URL = "https://api.imascars.co.uk/api/bookingPayments";

    try {
      if (canSave) {
        const bookingResponse = await addNewBooking({
          vehicle,
          dealership,
          pickUpLocation,
          dropOffLocation,
          pickUpLocationInDepth,
          dropOffLocationInDepth,
          startDate,
          endDate,
          startTime,
          endTime,
          dateOfBooking,
          orderNumber,
          totalPrice,
          isManualBooking,
          chauffeur,
          chauffeurFee,
          description,
          mainCustomer,
          additionalDriver,
          bookingStatus,
          complete,
          insuranceDetails,
          beforeImages,
          afterImages,
          additionalDriverFee,
          chauffeurDetails,
          bookingProgress,
        }).unwrap();

        if (bookingResponse.bookingId) {
          const response = await fetch(`${BASE_URL}/create-checkout-session`, {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
            },
            body: JSON.stringify({
              bookingId: bookingResponse.bookingId,
            }),
          });

          const { url } = await response.json();

          if (url) {
            window.location.href = url;
          }
        }
      }
    } catch (err) {
      console.error("Error:", err);
      setIsPaymentBooking(false); // Reset in case of error
    }
  };

  // console.log("vehicle:", vehicle);
  // console.log("dealership:", dealership);
  // console.log("pickUpLocation:", pickUpLocation);
  // console.log("dropOffLocation:", dropOffLocation);
  // console.log("startDate:", startDate);
  // console.log("endDate:", endDate);
  // console.log("dateOfBooking:", dateOfBooking);
  // console.log("orderNumber:", orderNumber);
  // console.log("totalPrice:", totalPrice);
  // console.log("chauffeur:", chauffeur);
  // console.log("description:", description);

  // console.log("mainCustomer:", mainCustomer);
  // console.log("additionalDriver:", additionalDriver);

  // console.log("bookingStatus:", bookingStatus);
  // console.log("complete:", complete);

  // console.log("insuranceDetails:", insuranceDetails);

  // console.log("beforeImages:", beforeImages);
  // console.log("afterImages:", afterImages);

  // continue from here
  const userOptions = users.map((user) => {
    // use this for the dealerhipTest
    return (
      <option key={user.id} value={user.id}>
        {" "}
        {user.email}
      </option>
    );
  });

  const vehicleOptions = vehicles.map((vehicle) => {
    // might have to change this to vehicles
    return (
      <option key={vehicle.id} value={vehicle.id}>
        {" "}
        {vehicle.registration}
      </option>
    );
  });

  // const startTimeOptions = vehicleAvailability
  //   ? vehicleAvailability.startTimes
  //   : [];
  // const endTimeOptions = vehicleAvailability
  //   ? vehicleAvailability.endTimes
  //   : [];

  const startTimeOptions = useMemo(() => {
    return vehicleAvailability ? vehicleAvailability.startTimes : [];
  }, [vehicleAvailability]);

  const endTimeOptions = useMemo(() => {
    return vehicleAvailability ? vehicleAvailability.endTimes : [];
  }, [vehicleAvailability]);

  useEffect(() => {
    if (startTimeOptions.length > 0) {
      setStartTime(startTimeOptions[0]);
    }
    if (endTimeOptions.length > 0) {
      setEndTime(endTimeOptions[0]);
    }
  }, [startTimeOptions, endTimeOptions]);

  const errClass = isError ? "errmsg" : "offscreen";
  const validVehicleClass = !vehicle ? "form__input--incomplete" : "";
  const validDealershipClass = !dealership ? "form__input--incomplete" : "";
  // const validDealershipTestClass = !dealershipTest
  //   ? "form__input--incomplete"
  //   : "";

  // const validDropOffLocationClass = !dropOffLocation
  //   ? "form__input--incomplete"
  //   : "";

  // Date Fields
  const validStartDateClass = !startDate ? "form__input--incomplete" : "";
  const validEndDateClass = !endDate ? "form__input--incomplete" : "";

  // Numeric Fields
  const validOrderNumberClass = !orderNumber ? "form__input--incomplete" : "";
  const validTotalPriceClass = !totalPrice ? "form__input--incomplete" : "";

  // Boolean Field
  const validChauffeurClass = chauffeur ? "" : "form__input--incomplete";

  // Main Customer Fields
  const validMainCustomerClass = !mainCustomer.customer
    ? "form__input--incomplete"
    : "";
  const validDrivingLicenseClass = !mainCustomer.drivingLicense.number
    ? "form__input--incomplete"
    : "";
  const validPassportClass = !mainCustomer.passport.number
    ? "form__input--incomplete"
    : "";
  const validDateOfBirthClass = !mainCustomer.dateOfBirth
    ? "form__input--incomplete"
    : "";
  const validAddressClass = !mainCustomer.address.line1
    ? "form__input--incomplete"
    : "";

  const validExtraDetailsClass = !mainCustomer.connections.email
    ? "form__input--incomplete"
    : "";

  // Additional Driver Fields (similar to Main Customer)
  const validAdditionalDriverClass = !additionalDriver.customer
    ? "form__input--incomplete"
    : "";
  const validAdditionalDriverDrivingLicenseClass = !additionalDriver
    .drivingLicense.number
    ? "form__input--incomplete"
    : "";
  const validAdditionalDriverPassportClass = !additionalDriver.passport.number
    ? "form__input--incomplete"
    : "";
  const validAdditionalDriverDateOfBirthClass = !additionalDriver.dateOfBirth
    ? "form__input--incomplete"
    : "";
  const validAdditionalDriverAddressClass = !additionalDriver.address.line1
    ? "form__input--incomplete"
    : "";

  const validAdditionalDriverExtraDetailsClass = !mainCustomer.connections.email
    ? "form__input--incomplete"
    : "";

  const content = (
    <>
      <p className={errClass} style={{ marginLeft: "100px" }}>
        {error?.data?.message}
      </p>
      <div className="newBookingForm" onSubmit={onSaveBookingClicked}>
        <h3
          className="modelAndModelVariantBookingForm"
          style={{ marginLeft: "15px" }}
        >
          {selectedVehicle &&
            `${selectedVehicle.make} ${selectedVehicle.model}`}
        </h3>
        <div className="newBookingFormSaveBtn">
          {selectedVehicle?.payments === false ? (
            // Show original Book button when payments are disabled
            <button
              className="saveBtn"
              title="save"
              disabled={!canSave}
              onClick={onSaveBookingClicked}
            >
              Book Vehicle
            </button>
          ) : (
            // Show the new button that triggers the modal when payments are enabled
            <button
              className="saveBtn"
              title="save"
              disabled={!canSave}
              onClick={openModal}
            >
              Book Vehicle
            </button>
          )}

          {/* Modal for Pay Now - Only shows when selectedVehicle?.payments is true */}
          {selectedVehicle?.payments && (
            <>
              <Modal
                isOpen={isModalOpen}
                onRequestClose={closeModal}
                className="modalContent"
                overlayClassName="modalOverlay"
              >
                <div className="modalHeader">
                  <h2>Book and Pay Now?</h2>
                  <button className="closeButton" onClick={closeModals}>
                    ×
                  </button>
                </div>
                <div className="modalBody">
                  <p>Choose how you’d like to proceed</p>
                  <div className="modalActions">
                    <button
                      className="requestBtn"
                      onClick={onSaveBookingClicked}
                    >
                      Request to Book
                    </button>
                    <button className="payNowBtn" onClick={handlePayNowClick}>
                      Book and Pay
                    </button>
                  </div>
                </div>
              </Modal>

              <Modal
                isOpen={isSecondaryModalOpen}
                onRequestClose={closeModal}
                className="modalContent"
                overlayClassName="modalOverlay"
              >
                <div className="modalHeader">
                  <h2>Are all The Details Correct?</h2>
                  <button className="closeButton" onClick={closeModals}>
                    ×
                  </button>
                </div>
                <div className="modalBody">
                  <p>
                    Clicking Pay Now Will Create the Booking Request Regardless
                    if Payment Goes Through
                  </p>
                  <div className="modalActions">
                    <button className="requestBtn" onClick={closeModals}>
                      Go Back
                    </button>
                    <button className="payNowBtn" onClick={handleBookAndPay}>
                      Pay Now
                    </button>
                  </div>
                </div>
              </Modal>
            </>
          )}
        </div>
        <div className="vehicleDetailsBookingForm">
          <div className="table-container">
            <p>Vehicle Details</p>
            <table>
              <thead>
                <tr>
                  <th>Technical Details</th>
                  <th>Year</th>
                  <th>Colour</th>
                  <th>Drivetrain</th>
                  <th>Bodytype</th>
                  <th>Seats</th>
                  <th>Doors</th>
                  <th>Power</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>{selectedVehicle.details}</td>
                  <td>{selectedVehicle.year}</td>
                  <td>{selectedVehicle.colour}</td>
                  <td>{selectedVehicle.drivetrain}</td>
                  <td>{selectedVehicle.bodyType}</td>
                  <td>{selectedVehicle.seats} Seats</td>
                  <td>{selectedVehicle.doors} Doors</td>
                  <td>{selectedVehicle.power} Hp</td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>

        <div className="bookingFormInputFields">
          <div className="customerDetailsBookingForm">
            <div className="sectionHeader">
              <p>Customer Details</p>
            </div>

            <div className="personalDetailsBookingForm">
              <p>Personal</p>

              <label className="form__label" htmlFor="customer">
                Customer
              </label>
              <select
                id="customer"
                name="customer"
                className={`form__input__booking ${validMainCustomerClass}`}
                value={mainCustomer.customer}
                onChange={onMainCustomerChanged}
                disabled
              >
                {userOptions}
              </select>
              {/* Date of Birth */}
              <label className="form__label" htmlFor="dateOfBirth">
                Date of Birth
              </label>
              <span>Date of Birth</span>
              <input
                className={`form__input__booking ${validDateOfBirthClass}`}
                id="dateOfBirth"
                name="dateOfBirth"
                type="date"
                autoComplete="off"
                value={mainCustomer.dateOfBirth}
                onChange={onMainCustomerChanged}
                placeholder="Date of Birth"
              />

              {/* Address */}
              <label className="form__label" htmlFor="address.line1">
                Address Line 1
              </label>
              <input
                className={`form__input__booking ${validAddressClass}`}
                id="address.line1"
                name="address.line1"
                type="text"
                autoComplete="off"
                value={mainCustomer.address.line1}
                onChange={onMainCustomerChanged}
                placeholder="Address Line 1"
              />

              <label className="form__label" htmlFor="address.line2">
                Address Line 2
              </label>
              <input
                className={`form__input__booking ${validAddressClass}`}
                id="address.line2"
                name="address.line2"
                type="text"
                autoComplete="off"
                value={mainCustomer.address.line2}
                onChange={onMainCustomerChanged}
                placeholder="Address Line 2"
              />

              <label className="form__label" htmlFor="address.city">
                City
              </label>
              <input
                className={`form__input__booking ${validAddressClass}`}
                id="address.city"
                name="address.city"
                type="text"
                autoComplete="off"
                value={mainCustomer.address.city}
                onChange={onMainCustomerChanged}
                placeholder="City"
              />

              <label className="form__label" htmlFor="address.county">
                County
              </label>
              <input
                className={`form__input__booking ${validAddressClass}`}
                id="address.county"
                name="address.county"
                type="text"
                autoComplete="off"
                value={mainCustomer.address.county}
                onChange={onMainCustomerChanged}
                placeholder="County"
              />

              <label className="form__label" htmlFor="address.postCode">
                Post Code
              </label>
              <input
                className={`form__input__booking ${validAddressClass}`}
                id="address.postCode"
                name="address.postCode"
                type="text"
                autoComplete="off"
                value={mainCustomer.address.postCode}
                onChange={onMainCustomerChanged}
                placeholder="Postcode"
              />
            </div>

            <div className="drivingLisenceDetails">
              <p>Driving Lisence Details</p>
              {/* Driving License */}
              <label className="form__label" htmlFor="drivingLicense.number">
                Driving License Number
              </label>
              <input
                className={`form__input__booking ${validDrivingLicenseClass}`}
                id="drivingLicense.number"
                name="drivingLicense.number"
                type="text"
                autoComplete="off"
                value={mainCustomer.drivingLicense.number}
                onChange={onMainCustomerChanged}
                placeholder="Driving License Number"
              />

              <label
                className="form__label"
                htmlFor="drivingLicense.dateOfIssue"
              >
                Date of Issue (Driving License)
              </label>
              <span>Date of Issue</span>
              <input
                className={`form__input__booking ${validDrivingLicenseClass}`}
                id="drivingLicense.dateOfIssue"
                name="drivingLicense.dateOfIssue"
                type="date"
                autoComplete="off"
                value={mainCustomer.drivingLicense.dateOfIssue}
                onChange={onMainCustomerChanged}
                placeholder="Date of Issue (Driving License)"
              />

              <label
                className="form__label"
                htmlFor="drivingLicense.dateOfExpiry"
              >
                Date of Expiry (Driving License)
              </label>
              <span>Date of Expiry</span>
              <input
                className={`form__input__booking ${validDrivingLicenseClass}`}
                id="drivingLicense.dateOfExpiry"
                name="drivingLicense.dateOfExpiry"
                type="date"
                autoComplete="off"
                value={mainCustomer.drivingLicense.dateOfExpiry}
                onChange={onMainCustomerChanged}
                placeholder="Date of Expiry (Driving License)"
              />

              <label
                className="form__label"
                htmlFor="drivingLicense.placeOfIssue"
              >
                Place of Issue (Driving License)
              </label>
              <input
                className={`form__input__booking ${validDrivingLicenseClass}`}
                id="drivingLicense.placeOfIssue"
                name="drivingLicense.placeOfIssue"
                type="text"
                autoComplete="off"
                value={mainCustomer.drivingLicense.placeOfIssue}
                onChange={onMainCustomerChanged}
                placeholder="Place of Issue (Driving License)"
              />
            </div>

            <div className="passportDetails">
              {/* Passport */}
              <p>Passport Details</p>
              <label className="form__label" htmlFor="passport.number">
                Passport Number
              </label>
              <input
                className={`form__input__booking ${validPassportClass}`}
                id="passport.number"
                name="passport.number"
                type="text"
                autoComplete="off"
                value={mainCustomer.passport.number}
                onChange={onMainCustomerChanged}
                placeholder="Passport Number"
              />

              <label className="form__label" htmlFor="passport.dateOfIssue">
                Date of Issue (Passport)
              </label>
              <span>Date of Issue</span>
              <input
                className={`form__input__booking ${validPassportClass}`}
                id="passport.dateOfIssue"
                name="passport.dateOfIssue"
                type="date"
                autoComplete="off"
                value={mainCustomer.passport.dateOfIssue}
                onChange={onMainCustomerChanged}
                placeholder="Date of Issue (Passport)"
              />

              <label className="form__label" htmlFor="passport.dateOfExpiry">
                Date of Expiry (Passport)
              </label>
              <span>Date of Expiry</span>
              <input
                className={`form__input__booking ${validPassportClass}`}
                id="passport.dateOfExpiry"
                name="passport.dateOfExpiry"
                type="date"
                autoComplete="off"
                value={mainCustomer.passport.dateOfExpiry}
                onChange={onMainCustomerChanged}
                placeholder="Date of Expiry (Passport)"
              />
            </div>
            <div className="extraDetailsConnections">
              <p>Extra Details</p>

              <label className="form__label" htmlFor="connections.email">
                email
              </label>
              <input
                className={`form__input__booking ${validExtraDetailsClass}`}
                id="connections.email"
                name="connections.email"
                type="text"
                autoComplete="off"
                value={mainCustomer.connections.email}
                onChange={onMainCustomerChanged}
                onFocus={(e) => {
                  if (e.target.value === "email") {
                    e.target.value = "";
                  }
                }}
                onBlur={(e) => {
                  if (e.target.value === "") {
                    e.target.value = "email";
                  }
                }}
                placeholder="email"
              />

              <label className="form__label" htmlFor="connections.number">
                number
              </label>
              <input
                className={`form__input__booking ${validExtraDetailsClass}`}
                id="connections.number"
                name="connections.number"
                type="number"
                autoComplete="off"
                value={mainCustomer.connections.number}
                onChange={onMainCustomerChanged}
                placeholder="number"
              />
            </div>
          </div>

          {/* just making a gap lol  */}

          <div className="bookingDetails">
            <div className="sectionHeaderBookingDetails">
              <p>Booking Details</p>
            </div>
            <div
              className={`pickUpAndDropOffLocation ${
                pickUpLocationInDepth.type === "Custom" ||
                dropOffLocationInDepth.type === "Custom"
                  ? "custom-padding"
                  : ""
              }`}
            >
              {/* <p>Pick Up Location</p>
              <label
                className="form__label--visually-hidden"
                htmlFor="pickUpLocation"
              >
                Pick Up Location
              </label>
              <input
                className={`form__input__booking ${validPickUpLocationClass}`}
                id="pickUpLocation"
                name="pickUpLocation"
                type="text"
                autoComplete="off"
                value={pickUpLocation}
                onChange={onPickUpLocationChanged}
                onFocus={(e) => {
                  if (e.target.value === "pickUpLocation") {
                    e.target.value = "";
                  }
                }}
                onBlur={(e) => {
                  if (e.target.value === "") {
                    e.target.value = "pickUpLocation";
                  }
                }}
                placeholder="pickUpLocation"
              />

              <p>Drop Off Location</p>
              <label
                className="form__label--visually-hidden"
                htmlFor="dropOffLocation"
              >
                Drop Off Location
              </label>
              <input
                className={`form__input__booking ${validDropOffLocationClass}`}
                id="dropOffLocation"
                name="dropOffLocation"
                type="text"
                autoComplete="off"
                value={dropOffLocation}
                onChange={onDropOffLocationChanged}
                onFocus={(e) => {
                  if (e.target.value === "dropOffLocation") {
                    e.target.value = "";
                  }
                }}
                onBlur={(e) => {
                  if (e.target.value === "") {
                    e.target.value = "dropOffLocation";
                  }
                }}
                placeholder="dropOffLocation"
              /> */}
              <Tooltip
                id="infoTooltip"
                place="top"
                type="dark"
                effect="float"
              />

              <p
                data-tooltip-id="infoTooltip"
                data-tooltip-content="If you select dealership you must collect from dealership, if you choose home delivery the vehicle will be deivered to you (there is a charge dependant on distance)"
              >
                Pick Up Location Details
              </p>
              <label className="form__label--visually-hidden">
                Pick Up Location Type:{" "}
              </label>
              <select
                name="type"
                value={pickUpLocationInDepth.type}
                onChange={handlePickUpLocationChange}
                className={`form__input__booking ${validDealershipClass}`}
              >
                <option value="Dealership">Dealership</option>
                {vehicleHomeDelivery && (
                  <option value="Custom">Home Delivery</option>
                )}
              </select>

              {pickUpLocationInDepth.type === "Custom" && (
                <div className="customLocationNewBookingForm">
                  <input
                    type="text"
                    name="line1"
                    value={pickUpLocationInDepth.address.line1}
                    onChange={handlePickUpLocationChange}
                    placeholder="Address Line 1"
                    className={`form__input__booking `}
                  />
                  <input
                    type="text"
                    name="line2"
                    value={pickUpLocationInDepth.address.line2}
                    onChange={handlePickUpLocationChange}
                    placeholder="Address Line 2"
                    className={`form__input__booking `}
                  />
                  <input
                    type="text"
                    name="city"
                    value={pickUpLocationInDepth.address.city}
                    onChange={handlePickUpLocationChange}
                    placeholder="City"
                    className={`form__input__booking `}
                  />
                  <input
                    type="text"
                    name="county"
                    value={pickUpLocationInDepth.address.county}
                    onChange={handlePickUpLocationChange}
                    placeholder="County"
                    className={`form__input__booking `}
                  />
                  <input
                    type="text"
                    name="postCode"
                    value={pickUpLocationInDepth.address.postCode}
                    onChange={handlePickUpLocationChange}
                    placeholder="Post Code"
                    className={`form__input__booking `}
                  />
                </div>
              )}
              <p
                data-tooltip-id="infoTooltip"
                data-tooltip-content="If you select dealership you must return the vehicle at the dealership, if you choose home return the vehicle will be collected (there is a charge dependant on distance)"
              >
                Return Location Details
              </p>
              <label className="form__label--visually-hidden">
                Drop Off Location Type:{" "}
              </label>
              <select
                name="type"
                value={dropOffLocationInDepth.type}
                onChange={handleDropOffLocationChange}
                className={`form__input__booking `}
              >
                <option value="Dealership">Dealership</option>
                {vehicleHomeDelivery && (
                  <option value="Custom">Home Return</option>
                )}
              </select>

              {dropOffLocationInDepth.type === "Custom" && (
                <div className="customLocationNewBookingForm">
                  <input
                    type="text"
                    name="line1"
                    value={dropOffLocationInDepth.address.line1}
                    onChange={handleDropOffLocationChange}
                    placeholder="Address Line 1"
                    className={`form__input__booking`}
                  />
                  <input
                    type="text"
                    name="line2"
                    value={dropOffLocationInDepth.address.line2}
                    onChange={handleDropOffLocationChange}
                    placeholder="Address Line 2"
                    className={`form__input__booking`}
                  />
                  <input
                    type="text"
                    name="city"
                    value={dropOffLocationInDepth.address.city}
                    onChange={handleDropOffLocationChange}
                    placeholder="City"
                    className={`form__input__booking`}
                  />
                  <input
                    type="text"
                    name="county"
                    value={dropOffLocationInDepth.address.county}
                    onChange={handleDropOffLocationChange}
                    placeholder="County"
                    className={`form__input__booking`}
                  />
                  <input
                    type="text"
                    name="postCode"
                    value={dropOffLocationInDepth.address.postCode}
                    onChange={handleDropOffLocationChange}
                    placeholder="Post Code"
                    className={`form__input__booking`}
                  />
                </div>
              )}
            </div>

            <div
              className={`chauffeurDetails ${
                pickUpLocationInDepth.type === "Custom" ||
                dropOffLocationInDepth.type === "Custom"
                  ? "custom-padding"
                  : ""
              }`}
            >
              <p>Chauffeur?</p>
              <label className="form__label" htmlFor="chauffeur">
                Chauffeur Service
              </label>
              <input
                className={`form__input__booking__chauffeur ${validChauffeurClass}`}
                id="chauffeur"
                name="chauffeur"
                type="checkbox" // Set the type to "checkbox" for a boolean input
                checked={chauffeur} // Use the checked attribute to control the checkbox state
                onChange={onChauffeurChanged} // Provide an onChange handler to update the state
                disabled={!selectedVehicle?.chauffeurAvailable}
              />
              <p>
                Chauffeur Details (please include an accurate description of
                requirements)
              </p>
              <textarea
                className="form__input__booking__chauffeurDetails"
                id="chauffeurDetails"
                name="Chauffeur Details"
                value={chauffeurDetails}
                onChange={onChauffeurDetailsChanged}
                placeholder="Chauffeur Details"
              ></textarea>
            </div>

            <div
              className={`durationDetails ${
                pickUpLocationInDepth.type === "Custom" ||
                dropOffLocationInDepth.type === "Custom"
                  ? "custom-padding"
                  : ""
              }`}
            >
              <p>Duration</p>
              <div className="durationInputFields">
                <label className="form__label" htmlFor="startDate">
                  Start Date
                </label>
                <span>Start Date</span>
                <input
                  className={`form__input__booking ${validStartDateClass}`}
                  id="startDate"
                  name="startDate"
                  type="date" // Set the type to "date" for date input
                  value={startDate}
                  onChange={onStartDateChanged}
                  required // Add the "required" attribute for validation
                />

                <label className="form__label" htmlFor="endDate">
                  End Date
                </label>
                <span>End Date</span>
                <input
                  className={`form__input__booking ${validEndDateClass}`}
                  id="endDate"
                  name="endDate"
                  type="date" // Set the type to "date" for date input
                  value={endDate}
                  onChange={onEndDateChanged}
                  required // Add the "required" attribute for validation
                />
              </div>
            </div>
            <div
              className={`startEndDetails ${
                pickUpLocationInDepth.type === "Custom" ||
                dropOffLocationInDepth.type === "Custom"
                  ? "custom-padding"
                  : ""
              }`}
            >
              <div className="startEndInputFields">
                <p>Start Time</p>
                <label htmlFor="startTime" className="form__label">
                  Start Time:
                </label>
                <select
                  id="startTime"
                  value={startTime}
                  onChange={onStartTimeChanged}
                  className="form__input__booking"
                >
                  {startTimeOptions.map((option) => (
                    <option key={option} value={option}>
                      {option}
                    </option>
                  ))}
                </select>
                <p>End Time</p>
                <label htmlFor="endTime" className="form__label">
                  End Time:
                </label>
                <select
                  id="endTime"
                  value={endTime}
                  onChange={onEndTimeChanged}
                  className="form__input__booking"
                >
                  {endTimeOptions.map((option) => (
                    <option key={option} value={option}>
                      {option}
                    </option>
                  ))}
                </select>
              </div>
            </div>
          </div>

          <div className="additionalDriverBookingForm">
            <div className="sectionHeaderAdditionalDriver">
              <p>Additional Driver Details</p>
            </div>

            <div className="personalDetailsAdditionalDriver">
              <p>Personal</p>

              <label
                className="form__label--visually-hidden"
                htmlFor="additionalCustomer"
              >
                Additional Customer
              </label>
              <input
                id="customer"
                name="customer"
                className={`form__input__booking ${validAdditionalDriverClass}`}
                type="text"
                value={additionalDriver.customer}
                onChange={onAdditionalDriverChanged}
                placeholder="Full Name"
              />

              {/* Date of Birth */}
              <label className="form__label" htmlFor="dateOfBirth">
                Date of Birth
              </label>
              <span>Date of Birth</span>
              <input
                className={`form__input__booking ${validAdditionalDriverDateOfBirthClass}`}
                id="dateOfBirth"
                name="dateOfBirth"
                type="date"
                autoComplete="off"
                value={additionalDriver.dateOfBirth}
                onChange={onAdditionalDriverChanged}
                placeholder="Date of Birth"
              />

              {/* Address */}
              <label className="form__label" htmlFor="address.line1">
                Address Line 1
              </label>
              <input
                className={`form__input__booking ${validAdditionalDriverAddressClass}`}
                id="address.line1"
                name="address.line1"
                type="text"
                autoComplete="off"
                value={additionalDriver.address.line1}
                onChange={onAdditionalDriverChanged}
                placeholder="Address Line 1"
              />

              <label className="form__label" htmlFor="address.line2">
                Address Line 2
              </label>
              <input
                className={`form__input__booking ${validAdditionalDriverAddressClass}`}
                id="address.line2"
                name="address.line2"
                type="text"
                autoComplete="off"
                value={additionalDriver.address.line2}
                onChange={onAdditionalDriverChanged}
                placeholder="Address Line 2"
              />

              <label className="form__label" htmlFor="address.city">
                City
              </label>
              <input
                className={`form__input__booking ${validAdditionalDriverAddressClass}`}
                id="address.city"
                name="address.city"
                type="text"
                autoComplete="off"
                value={additionalDriver.address.city}
                onChange={onAdditionalDriverChanged}
                placeholder="City"
              />

              <label className="form__label" htmlFor="address.county">
                County
              </label>
              <input
                className={`form__input__booking ${validAdditionalDriverAddressClass}`}
                id="address.county"
                name="address.county"
                type="text"
                autoComplete="off"
                value={additionalDriver.address.county}
                onChange={onAdditionalDriverChanged}
                placeholder="County"
              />

              <label className="form__label" htmlFor="address.postCode">
                Post Code
              </label>
              <input
                className={`form__input__booking ${validAdditionalDriverAddressClass}`}
                id="address.postCode"
                name="address.postCode"
                type="text"
                autoComplete="off"
                value={additionalDriver.address.postCode}
                onChange={onAdditionalDriverChanged}
                placeholder="Postcode"
              />
            </div>

            <div className="drivingLisenceDetailsAdditionalCustomer">
              <p>Driving Lisence Details</p>
              {/* Driving License */}
              <label className="form__label" htmlFor="drivingLicense.number">
                Driving License Number
              </label>
              <input
                className={`form__input__booking ${validAdditionalDriverDrivingLicenseClass}`}
                id="drivingLicense.number"
                name="drivingLicense.number"
                type="text"
                autoComplete="off"
                value={additionalDriver.drivingLicense.number}
                onChange={onAdditionalDriverChanged}
                placeholder="Driving License Number"
              />

              <label
                className="form__label"
                htmlFor="drivingLicense.dateOfIssue"
              >
                Date of Issue (Driving License)
              </label>
              <span>Date of Issue</span>
              <input
                className={`form__input__booking ${validAdditionalDriverDrivingLicenseClass}`}
                id="drivingLicense.dateOfIssue"
                name="drivingLicense.dateOfIssue"
                type="date"
                autoComplete="off"
                value={additionalDriver.drivingLicense.dateOfIssue}
                onChange={onAdditionalDriverChanged}
                placeholder="Date of Issue (Driving License)"
              />

              <label
                className="form__label"
                htmlFor="drivingLicense.dateOfExpiry"
              >
                Date of Expiry (Driving License)
              </label>
              <span>Date of Expiry</span>
              <input
                className={`form__input__booking ${validAdditionalDriverDrivingLicenseClass}`}
                id="drivingLicense.dateOfExpiry"
                name="drivingLicense.dateOfExpiry"
                type="date"
                autoComplete="off"
                value={additionalDriver.drivingLicense.dateOfExpiry}
                onChange={onAdditionalDriverChanged}
                placeholder="Date of Expiry (Driving License)"
              />

              <label
                className="form__label"
                htmlFor="drivingLicense.placeOfIssue"
              >
                Place of Issue (Driving License)
              </label>
              <input
                className={`form__input__booking ${validAdditionalDriverDrivingLicenseClass}`}
                id="drivingLicense.placeOfIssue"
                name="drivingLicense.placeOfIssue"
                type="text"
                autoComplete="off"
                value={additionalDriver.drivingLicense.placeOfIssue}
                onChange={onAdditionalDriverChanged}
                placeholder="Place of Issue (Driving License)"
              />
            </div>

            <div className="passportDetailsAdditionalDriver">
              <p>PassportDetails</p>
              {/* Passport */}
              <label className="form__label" htmlFor="passport.number">
                Passport Number
              </label>
              <input
                className={`form__input__booking ${validAdditionalDriverPassportClass}`}
                id="passport.number"
                name="passport.number"
                type="text"
                autoComplete="off"
                value={additionalDriver.passport.number}
                onChange={onAdditionalDriverChanged}
                placeholder="Passport Number"
              />

              <label className="form__label" htmlFor="passport.dateOfIssue">
                Date of Issue (Passport)
              </label>
              <span>Date of Issue</span>
              <input
                className={`form__input__booking ${validAdditionalDriverPassportClass}`}
                id="passport.dateOfIssue"
                name="passport.dateOfIssue"
                type="date"
                autoComplete="off"
                value={additionalDriver.passport.dateOfIssue}
                onChange={onAdditionalDriverChanged}
                placeholder="Date of Issue (Passport)"
              />

              <label className="form__label" htmlFor="passport.dateOfExpiry">
                Date of Expiry (Passport)
              </label>
              <span>Date of Expiry</span>
              <input
                className={`form__input__booking ${validAdditionalDriverPassportClass}`}
                id="passport.dateOfExpiry"
                name="passport.dateOfExpiry"
                type="date"
                autoComplete="off"
                value={additionalDriver.passport.dateOfExpiry}
                onChange={onAdditionalDriverChanged}
                placeholder="Date of Expiry (Passport)"
              />
            </div>

            <div className="extraDetailsConnectionsAdditionalDriver">
              <p>Extra Details</p>

              <label className="form__label" htmlFor="connections.email">
                email
              </label>
              <input
                className={`form__input__booking ${validAdditionalDriverExtraDetailsClass}`}
                id="connections.email"
                name="connections.email"
                type="text"
                autoComplete="off"
                value={additionalDriver.connections.email}
                onChange={onAdditionalDriverChanged}
                onFocus={(e) => {
                  if (e.target.value === "email") {
                    e.target.value = "";
                  }
                }}
                onBlur={(e) => {
                  if (e.target.value === "") {
                    e.target.value = "email";
                  }
                }}
                placeholder="email"
              />

              <label className="form__label" htmlFor="connections.number">
                number
              </label>
              <input
                className={`form__input__booking ${validAdditionalDriverExtraDetailsClass}`}
                id="connections.number"
                name="connections.number"
                type="number"
                autoComplete="off"
                value={additionalDriver.connections.number}
                onChange={onAdditionalDriverChanged}
                placeholder="number"
              />
            </div>
          </div>

          <div className="paymentDetails">
            <div className="paymentDetailsHeader">
              <p>Payment Details</p>
            </div>

            <div className="vehicleOption">
              <p>Vehicle</p>
              <label className="form__label--visually-hidden" htmlFor="vehicle">
                Vehicle
              </label>
              <select
                id="vehicle"
                name="vehicle"
                className={`form__input__booking ${validVehicleClass}`}
                value={vehicle}
                onChange={onVehicleChanged}
                disabled
              >
                {vehicleOptions}
              </select>
            </div>

            <div className="dealershipOption">
              <p>Dealership</p>
              <label
                className="form__label--visually-hidden"
                htmlFor="dealership"
              >
                Dealership
              </label>
              <select
                id="dealership"
                name="dealership"
                className={`form__input__booking ${validDealershipClass}`}
                value={dealership}
                onChange={onDealershipChanged}
                disabled
              >
                <option value="" disabled selected>
                  {dealershipName}
                </option>
              </select>
            </div>

            <div className="orderNumber">
              <p>Order Number</p>
              <label
                className="form__label--visually-hidden"
                htmlFor="orderNumber"
              >
                Order Number
              </label>
              <input
                className={`form__input__booking ${validOrderNumberClass}`}
                id="orderNumber"
                name="orderNumber"
                type="text"
                autoComplete="off"
                value={orderNumber}
                onChange={onOrderNumberChanged}
                onFocus={(e) => {
                  if (e.target.value === "orderNumber") {
                    e.target.value = "";
                  }
                }}
                onBlur={(e) => {
                  if (e.target.value === "") {
                    e.target.value = "orderNumber";
                  }
                }}
                placeholder="orderNumber"
                disabled
              />
            </div>

            <div className="totalPrice">
              <p> Estimated Total </p>
              <label
                className="form__label--visually-hidden"
                htmlFor="totalPrice"
              >
                Vehicle TotalPrice
              </label>
              <input
                className={`form__input__booking ${validTotalPriceClass}`}
                id="totalPrice"
                name="totalPrice"
                type="number"
                autoComplete="off"
                value={totalPrice}
                onChange={onTotalPriceChanged}
                disabled
                onFocus={(e) => {
                  if (e.target.value === "TotalPrice") {
                    e.target.value = "";
                  }
                }}
                onBlur={(e) => {
                  if (e.target.value === "") {
                    e.target.value = "TotalPrice";
                  }
                }}
                placeholder="TotalPrice"
              />
            </div>
          </div>
        </div>
      </div>
    </>
  );

  return content;
};

export default NewBookingForm;

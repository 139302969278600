import { loadStripe } from "@stripe/stripe-js";
import useAuth from "../../../hooks/useAuth";
import { FaCheck, FaInfoCircle, FaTimes } from "react-icons/fa";
// import { Tooltip } from "react-tooltip";
import { useState } from "react";

// const PLANS = [
//   { id: "price_1QC67wHq9UJEMCt77FO1teRL", label: "£300/month", amount: 300 },
//   {
//     id: "price_1QC68bHq9UJEMCt7hT32FygC",
//     label: "£1600/6 months",
//     amount: 1600,
//   },
//   { id: "price_1QC696Hq9UJEMCt7kjlD1IZj", label: "£2800/year", amount: 2800 },
// ];
// const PLANS = [
//   { id: "price_1QV96aHq9UJEMCt7BKGrrBof", label: "Free/Month", amount: 0 },
//   {
//     id: "price_1QV9BhHq9UJEMCt7SmKec7xq",
//     label: "Free/6Months",
//     amount: 0,
//   },
//   { id: "price_1QV9BxHq9UJEMCt7JEwMMeQB", label: "Free/Year", amount: 0 },

//   { id: "price_1QV97dHq9UJEMCt7OR2xHCql", label: "£150/Month", amount: 150 },
//   { id: "price_1QV9ExHq9UJEMCt7EaQeHCSP", label: "£810/6Months", amount: 810 },
//   { id: "price_1QV9F8Hq9UJEMCt7acYNKrLP", label: "£1530/Year", amount: 1530 },

//   { id: "price_1QV98AHq9UJEMCt711iUYyTT", label: "£1000/Month", amount: 1000 },
//   {
//     id: "price_1QV9JnHq9UJEMCt75cbFHEnu",
//     label: "£5400/6Months",
//     amount: 5400,
//   },
//   { id: "price_1QV9KFHq9UJEMCt75e3j32xl", label: "£9000/Year", amount: 9000 },
// ];

// Test plans for the new three account types

// const FREEPLANS = [
//   { id: "price_1QV96aHq9UJEMCt7BKGrrBof", label: "Free/Month", amount: 0 },
//   {
//     id: "price_1QV9BhHq9UJEMCt7SmKec7xq",
//     label: "Free/6Months",
//     amount: 0,
//   },
//   { id: "price_1QV9BxHq9UJEMCt7JEwMMeQB", label: "Free/Year", amount: 0 },
// ];

// const PROFESSIONALPLANS = [
//   { id: "price_1QV97dHq9UJEMCt7OR2xHCql", label: "150/Month", amount: 150 },
//   {
//     id: "price_1QV9ExHq9UJEMCt7EaQeHCSP",
//     label: "810/6Months",
//     amount: 810,
//   },
//   { id: "price_1QV9F8Hq9UJEMCt7acYNKrLP", label: "1530/Year", amount: 1530 },
// ];

// const ENTERPRISEPLANS = [
//   { id: "price_1Qb3RfHq9UJEMCt7gUIuxu7T", label: "500/Month", amount: 500 },
//   {
//     id: "price_1Qb3UwHq9UJEMCt7haFaeY5b",
//     label: "2500/6Months",
//     amount: 2500,
//   },
//   { id: "price_1Qb3VdHq9UJEMCt7dwdE8NO5", label: "3500/Year", amount: 3500 },
// ];

// live plans for the new account types

const FREEPLANS = [
  { id: "price_1QoPxqHq9UJEMCt7TB7ogrp8", label: "Free/Month", amount: 0 },
  {
    id: "price_1QoPxqHq9UJEMCt7cEJYLxcL",
    label: "Free/6Months",
    amount: 0,
  },
  { id: "price_1QoPxqHq9UJEMCt7NeWPedcS", label: "Free/Year", amount: 0 },
];

const PROFESSIONALPLANS = [
  { id: "price_1QoPxoHq9UJEMCt77vcVoBMD", label: "150/Month", amount: 150 },
  {
    id: "price_1QoPxoHq9UJEMCt7FjEhMMRa",
    label: "810/6Months",
    amount: 810,
  },
  { id: "price_1QoPxoHq9UJEMCt7eyeXIqBw", label: "1530/Year", amount: 1530 },
];

const ENTERPRISEPLANS = [
  { id: "price_1QoPxkHq9UJEMCt7h88OmbnI", label: "500/Month", amount: 500 },
  {
    id: "price_1QoPxkHq9UJEMCt7IIIYo3aN",
    label: "2500/6Months",
    amount: 2500,
  },
  { id: "price_1QoPxkHq9UJEMCt7Am3Tao2c", label: "3500/Year", amount: 3500 },
];

function getPlanTypeById(priceId) {
  // Check if the priceId matches any plan in the FreePlans
  if (FREEPLANS.some((plan) => plan.id === priceId)) {
    return "free"; // Plan is Free
  }

  // Check if the priceId matches any plan in the ProfessionalPlans
  if (PROFESSIONALPLANS.some((plan) => plan.id === priceId)) {
    return "professional"; // Plan is Professional
  }

  // Check if the priceId matches any plan in the EnterprisePlans
  if (ENTERPRISEPLANS.some((plan) => plan.id === priceId)) {
    return "enterprise"; // Plan is Enterprise
  }

  // If no match is found, return null or a default value
  return null;
}

const stripePromise = loadStripe(
  "pk_live_51Q6tCEHq9UJEMCt7qGPmZO3M4bz6gRO4gDV7MNhm6KuUS6SuHUc9suAMEXHyyyWLRWvK8trK72qmPXriD1B7OHkz008K1YWgKQ"
);

// test
// const stripePromise = loadStripe(
//   "pk_test_51Q6tCEHq9UJEMCt7AwUwvpdxJfFpD2w8dqdjudpn8ILyriFQzeuPiZzMpdscuveE8CqolCTO7W3fuiH2rPsD3ydm00nYLDTYC0"
// );

// const BASE_URL = "https://imas-cars-backend.onrender.com/api/payments";
const BASE_URL = "https://api.imascars.co.uk/api/payments";
// const BASE_URL = "http://localhost:3500/api/payments";

const Pricing = () => {
  const { customerId, email, stripeCustomerId } = useAuth();

  const [selectedOption, setSelectedOption] = useState("yearly");

  // Handle selection changes
  const handleChange = (event) => {
    setSelectedOption(event.target.value);
    console.log(`Selected option: ${event.target.value}`);
  };

  const planType = getPlanTypeById("price_1QV96aHq9UJEMCt7BKGrrBof");

  const handleCheckout = async (planId) => {
    const stripe = await stripePromise; // Get Stripe object

    // Create a checkout session for the selected plan
    const checkoutSession = await fetch(`${BASE_URL}/create-subscription`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        priceId: planId, // Send selected plan id to backend
        customerId,
        email,
        stripeCustomerId,
        planType,
        line_items: [
          {
            price: planId, // The priceId of the selected plan
            quantity: 1, // Quantity of the selected plan
          },
        ],
        // trialPeriodDays: 7,
        trialPeriodDays:
          planId === "price_1QV96aHq9UJEMCt7BKGrrBof" ||
          planId === "price_1QV9BhHq9UJEMCt7SmKec7xq" ||
          planId === "price_1QV9BxHq9UJEMCt7JEwMMeQB" // Free plan IDs
            ? null // Set trialPeriodDays to null for free plans (no trial)
            : 7, // 7-day trial for other plans
      }),
    });

    const sessionData = await checkoutSession.json();

    console.log(sessionData);

    // console.log(sessionData);

    // Redirect user to Stripe checkout page
    const { error } = await stripe.redirectToCheckout({
      sessionId: sessionData.sessionId, // Use sessionId returned from backend
    });

    if (error) {
      console.error("Error during Stripe redirection:", error.message);
    }
  };

  // Its for the enterpsie plan to work out the price

  const getPlanPrice = (option) => {
    switch (option) {
      case "monthly":
        return 500;
      case "per6Months":
        return 2500;
      case "yearly":
        return 3500;
      default:
        return 0;
    }
  };

  const currentPrice = getPlanPrice(selectedOption);
  const strikeThroughPrice = currentPrice * 2;

  // see above comment

  const content = (
    <div className="pricingDealer">
      <div className="pricingDealerHeader">
        <h3>Pricing (All paid plans come with a 7 day free trial)</h3>
        <select
          id="pricing"
          value={selectedOption}
          onChange={handleChange}
          style={{ padding: "5px", fontSize: "16px" }}
        >
          <option value="monthly">Monthly</option>
          <option value="per6Months">Per 6 Months</option>
          <option value="yearly">Yearly</option>
        </select>
      </div>
      <div className="pricingCardContainer">
        <div className="pricingCard otherPlan">
          <div className="pricingCardTopHalf">
            <h4>Free Tier</h4>
            {/* <span>£0 Per Month</span> */}
            <span>
              £0{" "}
              {selectedOption === "monthly"
                ? "Per Month"
                : selectedOption === "per6Months"
                ? "Per 6 Months"
                : "Yearly"}
            </span>
            <p>Unlimited Vehicles</p>
            <p>Upgrade Anytime</p>
            <p>Access to Financial Tools</p>
            <p>Access to Fleet Management Tools</p>
            <p>
              1.5% + 20p For Standard Uk Cards{" "}
              {/* <Tooltip
                id="infoTooltip"
                place="top"
                // type="dark"
                className="custom-tooltip"
                opacity={100}
                effect="float"
                style={{
                  backgroundColor: "rgba(0, 0, 0, 1)",
                  color: "#ffffff",
                }}
              /> */}
              <FaInfoCircle
                className="pricingCardInfoIcon"
                data-tooltip-id="infoTooltip"
                data-tooltip-content="This Fee is Taken By Our Payment Processor Stripe."
              />
            </p>
            <p>15% Commission</p>
            <p>
              <FaTimes />
            </p>
            <p>
              <FaTimes />
            </p>
          </div>
          <div className="pricingCardBottomHalf">
            {/* <button onClick={() => handleCheckout(PLANS[4].id)}>
              Continue
            </button> */}
            <button
              onClick={() => {
                const plan =
                  selectedOption === "monthly"
                    ? FREEPLANS[0].id
                    : selectedOption === "per6Months"
                    ? FREEPLANS[1].id
                    : FREEPLANS[2].id; // Default to yearly if no match
                handleCheckout(plan);
              }}
            >
              Continue
            </button>
          </div>
        </div>
        <div className="pricingCard otherPlan">
          <div className="pricingCardTopHalf">
            <h4>Professional</h4>
            {/* <span>£150 Per Month</span> */}
            <span>
              £
              {selectedOption === "monthly"
                ? "150"
                : selectedOption === "per6Months"
                ? "810"
                : "1530"}{" "}
              {selectedOption === "monthly"
                ? "Per Month"
                : selectedOption === "per6Months"
                ? "Per 6 Months (10% Saved)"
                : "Yearly (15% Saved)"}
            </span>
            <p>Unlimited Vehicles</p>
            <p>Cancel Anytime</p>
            <p>Access to Financial Tools</p>
            <p>Access to Fleet Management Tools</p>
            <p>
              1.5% + 20p For Standard Uk Cards{" "}
              <FaInfoCircle
                className="pricingCardInfoIcon"
                data-tooltip-id="infoTooltip"
                data-tooltip-content="This Fee is Taken By Our Payment Processor Stripe."
              />
            </p>
            <p>
              <FaCheck className="pricingCardTickMiddlePlan" />
              5% Commission
            </p>
            <p>
              <FaCheck className="pricingCardTickMiddlePlan" />
              50 Manual Bookings 0% Commission{" "}
              <FaInfoCircle
                className="pricingCardInfoIcon"
                data-tooltip-id="infoTooltip"
                data-tooltip-content="A Manual Booking Is a Booking Which you get elsewhere eg Phone. You are still able to add the booking details onto the site on your private dashboard"
              />
            </p>
            <p>
              <FaCheck className="pricingCardTickMiddlePlan" />
              50 Booking Requests 0% Commission
              <FaInfoCircle
                className="pricingCardInfoIcon"
                data-tooltip-id="infoTooltip"
                data-tooltip-content="A Booking Request is a booking made through the site by your customers without the need for them to pay through the site."
              />
            </p>
          </div>
          <div className="pricingCardBottomHalf">
            {/* <button onClick={() => handleCheckout(PLANS[1].id)}>
              Continue
            </button> */}
            <button
              onClick={() => {
                const plan =
                  selectedOption === "monthly"
                    ? PROFESSIONALPLANS[0].id
                    : selectedOption === "per6Months"
                    ? PROFESSIONALPLANS[1].id
                    : PROFESSIONALPLANS[2].id; // Default to yearly if no match
                handleCheckout(plan);
              }}
            >
              Continue
            </button>
          </div>
        </div>
        <div className="pricingCard enterprisePlan">
          <div className="bannerEnterprisePlan">
            50% Off, Limited Account Slots.
          </div>
          <div className="pricingCardTopHalf">
            <h4>Enterprise</h4>
            {/*        
            <span>
              <span className="enterprisePlanStrikeThrough">£1000</span>£500 Per
              Month
            </span> */}
            <span>
              <span className="enterprisePlanStrikeThrough">
                £{strikeThroughPrice}
              </span>
              £{currentPrice}{" "}
              {selectedOption === "monthly"
                ? "Per Month"
                : selectedOption === "per6Months"
                ? "Per 6 Months (16.67% Saved)"
                : "Yearly (41.67% Saved)"}
            </span>
            <p>Unlimited Vehicles</p>
            <p>Cancel Anytime</p>
            <p>Access to Financial Tools</p>
            <p>Access to Fleet Management Tools</p>
            <p>
              1.5% + 20p For Standard Uk Cards{" "}
              <FaInfoCircle
                className="pricingCardInfoIcon"
                data-tooltip-id="infoTooltip"
                data-tooltip-content="This Fee is Taken By Our Payment Processor Stripe."
              />
            </p>
            <p>
              <FaCheck className="pricingCardTick" />
              0% Commission
            </p>
            <p>
              <FaCheck className="pricingCardTick" />
              Unlimited Manual Bookings{" "}
              <FaInfoCircle
                className="pricingCardInfoIcon"
                data-tooltip-id="infoTooltip"
                data-tooltip-content="A Manual Booking Is a Booking Which you get elsewhere eg Phone. You are still able to add the booking details onto the site on your private dashboard. 0% Commission Taken"
              />
            </p>
            <p>
              <FaCheck className="pricingCardTick" />
              Unlimited Booking Requests{" "}
              <FaInfoCircle
                className="pricingCardInfoIcon"
                data-tooltip-id="infoTooltip"
                data-tooltip-content="A Booking Request is a booking made through the site by your customers without the need for them to pay through the site. 0% Commission Taken"
              />
            </p>
          </div>
          <div className="pricingCardBottomHalf">
            {/* <button onClick={() => handleCheckout(PLANS[2].id)}>
              Continue
            </button> */}
            <button
              onClick={() => {
                const plan =
                  selectedOption === "monthly"
                    ? ENTERPRISEPLANS[0].id
                    : selectedOption === "per6Months"
                    ? ENTERPRISEPLANS[1].id
                    : ENTERPRISEPLANS[2].id; // Default to yearly if no match
                handleCheckout(plan);
              }}
            >
              Continue
            </button>
          </div>
        </div>
      </div>
      <div className="pricingDealerInfoBox">
        <h2>
          Import Information{" "}
          <FaInfoCircle className="pricingCardInfoIconDealerInfoBox" />
        </h2>
        <p className="pricingDealerInfoBoxHeading">What is a Manual Booking?</p>
        <p>
          A Manual Booking is a booking you can enter manually. For Example when
          you get an order Via Phone, You can still enter in the booking details
          onto your private dashbaord under the Manual bookings tab. This way
          you can still get sales data and make use of the fleet management
          tools for this booking along with all other bookings.
        </p>
        <p className="pricingDealerInfoBoxHeading">
          What is a Booking Request?
        </p>
        <p>
          A Booking Request is a booking made through the site without payment.
          Meaning the your customer can use the website to request to book a
          vehicle without paying. If you decide to accept their booking you can
          go about geting payment through your own means.
        </p>
        <p className="pricingDealerInfoBoxHeading">
          Can I disable payments on my bookings?
        </p>
        <p>
          If you decide you dont want your customers paying through the site you
          can disable payments and this way only requests will go through.
          However if you chose the free tier plan then this is not possible. On
          the enterpise plan once you exceed the 50 Booking Request Limit the
          payments will turn back on. On the Enterprise tier the payments will
          stay off/on depending on what you choose as you have unlimited booking
          requests.
        </p>
        <p className="pricingDealerInfoBoxHeading">What Fees do you charge?</p>
        <p>
          Depending on the plan you chose, the fee is different. The free tier
          we take 15% Commission on top of the payment processor fee. For the
          Professional Plan we take 5% on top of the payment processor fee. For
          the Enterprise plan we take 0% + the payment processor fee.
        </p>
        <p className="pricingDealerInfoBoxHeading">
          Explain the Payment Processor Fees
        </p>
        <p>
          The Payment Processor we use is Stripe. They take 1.5% + 20p (standard
          UK card) per transaction and then a further 10p + 0.25% per payout. If
          the customer uses an EU Card then the fee is 2.5% + 20p. For example
          if your customer hires a vehicle for a total of £2000 then £30.20 will
          be taken by stripe for the payment processor fee. Then when stripe
          pays you out for the rest they take a further £5.10. All together this
          fee is £35.30 for a £2000 booking. We are working to find an
          alternative cheaper solution.
        </p>
        <p className="pricingDealerInfoBoxHeading">
          What are the savings when choosing Yearly or Per 6 Months?
        </p>
        <p>
          For the Professional plan choosing yearly saves 15% (Compared to
          Monthly Professional Plan.) <br />
          Choosing Per 6 Months Saves 10% (Compared to Monthly Professional
          Plan.)
          <br />
          <br />
          For the Enterprise Plan Choosing yearly saves 41.67% Or 5 Months free
          or ~£292 Per Month (Compared to Monthly Enterprise Plan).
          <br />
          For the Enterprise Plan Choosing Per 6 Months Saves 16.67% or 1 Month
          Free or ~£417 Per Month (Compared to Monthly Enterprise Plan)
        </p>
        <p className="pricingDealerInfoBoxHeading">Have a Question?</p>
        <p>
          Email us! IMAS-Cars@outlook.com Alternatively Contact Your Sales
          Representitive.
        </p>
      </div>
    </div>
  );

  return content;
};

export default Pricing;

import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useGetUsersQuery } from "../../users/usersApiSlice";
import useAuth from "../../../hooks/useAuth";
import { HashLoader } from "react-spinners";
import { FaStripe } from "react-icons/fa";

async function getUserLocation(latitude, longitude) {
  try {
    const response = await fetch(
      `https://nominatim.openstreetmap.org/reverse?format=jsonv2&lat=${latitude}&lon=${longitude}`
    );
    const data = await response.json();

    const location = {
      city: data.address.city,
      region: data.address.state,
      country: data.address.country,
    };

    return location;
  } catch (error) {
    console.error("Error fetching location:", error);
    return null; // Or return a default location if desired
  }
}

const BASE_URL = "https://api.imascars.co.uk/api/payments";
const BASE_URL_ONBOARDING = "https://api.imascars.co.uk/api/onboarding";

// const BASE_URL = "http://localhost:3500/api/payments";
// const BASE_URL_ONBOARDING = "http://localhost:3500/api/onboarding";

const DashboardProfile = () => {
  const {
    email,
    stripeCustomerId,
    stripeConnectAccountId,
    stripeOnboardingComplete,
  } = useAuth();

  const navigate = useNavigate();

  const {
    data: users,
    isLoading,
    isSuccess,
    isError,
    error,
  } = useGetUsersQuery(undefined, {
    pollingInterval: 60000,
    refetchOnFocus: true,
    refetchOnMountOrArgChange: true,
  });

  const [filteredUser, setFilteredUser] = useState(null); // Use a state for filteredUser
  const [userLocation, setUserLocation] = useState(null);

  const [accountCreatePending, setAccountCreatePending] = useState(false);
  const [accountLinkCreatePending, setAccountLinkCreatePending] =
    useState(false);
  // const [connectedAccountId, setConnectedAccountId] = useState(null);
  const [connectedAccountId, setConnectedAccountId] = useState(
    stripeConnectAccountId || null
  );
  const [onboardingError, setOnboardingError] = useState(false);

  // console.log(connectedAccountId);

  useEffect(() => {
    if (isSuccess) {
      const filteredUserId = Object.keys(users.entities).find(
        (userId) => users.entities[userId].email === email
      );
      const user = filteredUserId ? users.entities[filteredUserId] : null;
      setFilteredUser(user); // Update filteredUser state

      if (filteredUser && filteredUser.location) {
        const { latitude, longitude } = filteredUser.location;
        getUserLocation(latitude, longitude)
          .then((location) => setUserLocation(location))
          .catch((error) => console.error("Error fetching location:", error));
      }
    }
  }, [isSuccess, users, email, filteredUser]); // Dependency array updated

  const handleManagePlan = async () => {
    try {
      // Fetch the session URL from your backend to manage the subscription
      const response = await fetch(`${BASE_URL}/manage-subscription`, {
        method: "POST", // Use POST since we're sending data in the body
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          stripeCustomerId, // Ensure this value is correctly set in the context
        }),
      });

      if (!response.ok) {
        throw new Error("Failed to create session");
      }

      // Extract the JSON response
      const data = await response.json();

      // console.log(data); // Log the response only once

      // Redirect the user to the Stripe Customer Portal
      window.location.href = data.url;
    } catch (error) {
      console.error("Error fetching the manage plan URL:", error);
    }
  };

  // stripe onboarding

  const handleOnboardStripe = async () => {
    setAccountCreatePending(true);
    setOnboardingError(false);

    try {
      // const response = await fetch(`${BASE_URL_ONBOARDING}/account`, {
      //   method: "POST",
      // });
      const response = await fetch(`${BASE_URL_ONBOARDING}/account`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ email }), // Send the user's email
      });

      const data = await response.json();

      console.log(data);

      if (data.accountId) {
        setConnectedAccountId(data.accountId);
      } else if (data.error) {
        setOnboardingError(true);
      }
    } catch (error) {
      console.error("Error creating Stripe account:", error);
      setOnboardingError(true);
    } finally {
      setAccountCreatePending(false);
    }
  };

  const handleAddInformation = async () => {
    setAccountLinkCreatePending(true);
    setOnboardingError(false);

    console.log(connectedAccountId);

    try {
      const response = await fetch(`${BASE_URL_ONBOARDING}/account-link`, {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({ account: connectedAccountId }),
      });
      const data = await response.json();

      if (data.url) {
        window.location.href = data.url;
      } else if (data.error) {
        setOnboardingError(true);
      }
    } catch (error) {
      console.error(error);
      setOnboardingError(true);
    } finally {
      setAccountLinkCreatePending(false);
    }
  };

  let content;

  if (isLoading) {
    content = <HashLoader color="#36d7b7" />;
  } else if (isError) {
    content = <p className="errmsg">{error?.data?.message}</p>;
  } else if (isSuccess) {
    const filteredUserId = Object.keys(users.entities).find(
      (userId) => users.entities[userId].email === email
    );

    // If a matching user is found, access it using its ID
    const filteredUser = filteredUserId ? users.entities[filteredUserId] : null;

    content = (
      <div className="dashboardCustomerProfilePage">
        <h1 className="dashboardCustomerProfilePageTitle">Profile</h1>
        <div className="dashboardCustomerProfilePageDetails">
          <p className="dashboardCustomerProfilePageMyDetailsPTag">
            My Details
          </p>
          <div class="dashboardCustomerProfilePageDetailsGrid">
            <div class="grid-item">{filteredUser.firstname}</div>
            <div class="grid-item">{filteredUser.lastname}</div>
            <div class="grid-item">{filteredUser.email}</div>
            <div class="grid-item">{filteredUser.telephone}</div>
            <div class="grid-item">{filteredUser.companyName}</div>
            <div class="grid-item">
              {userLocation ? (
                <>
                  {userLocation.city}, {userLocation.region},{" "}
                  {userLocation.country}
                </>
              ) : (
                <p>Location not available</p>
              )}
            </div>
          </div>
          <div className="dashboardCustomerProfilePageDetialsProfilePicture">
            <img
              src={filteredUser.profilePicture}
              alt={
                filteredUser.firstname +
                " " +
                filteredUser.lastname +
                "'s profile picture"
              }
              className="profile-picture-circle"
            />
          </div>
          <button
            onClick={() => {
              navigate(`/users/${filteredUser.id}`);
            }}
            className="dashboardCustomerProfileEditButton"
            style={{ cursor: "pointer" }}
          >
            Edit
          </button>
          <button
            className="dashboardDealerManagePlanButton"
            onClick={handleManagePlan}
          >
            Manage Plan
          </button>
        </div>

        {!stripeOnboardingComplete && (
          <div className="stripeOnboardingContainer">
            <div className="stripeOnboardingContainerHeader">
              <h3>Accept Payments</h3>
              <div className="stripeOnboardingContainerLine"></div>
            </div>
            <div className="stripeOnboardingContainerBody">
              <div className="stripeOnboardingContainerBodyWarning">
                <div className={"stripeOnboardingContainerBodyWarningSpan"}>
                  <p>!</p>
                  {/* <p>{stripeOnboardingComplete ? "✓" : "!"}</p> */}
                </div>
                <span>Payment Processing Inactive</span>
              </div>
              <div className="stripeOnboardingContainerBodyText">
                <h3>Currently You Cannot Accept Payments Through The Site.</h3>
                <br />
                <p>
                  Your customers can still request to book through the site +
                  all other functionality.
                </p>
                <br />
                <p>
                  To accept payments through the site you need to onboard onto
                  stripe through the button below.
                </p>
                <div className="stripeOnboardingContainerBodyConfirm">
                  🔒 Secure Verification Powered By{" "}
                  <FaStripe className="stripeIcon" />
                  {!connectedAccountId && (
                    <button
                      onClick={handleOnboardStripe}
                      disabled={accountCreatePending}
                      className="stripeOnboardingButton"
                    >
                      {accountCreatePending
                        ? "Creating account..."
                        : "Start Stripe Onboarding"}
                    </button>
                  )}
                  {connectedAccountId && (
                    <button
                      onClick={handleAddInformation}
                      disabled={accountLinkCreatePending}
                      className="stripeOnboardingButton"
                    >
                      {accountLinkCreatePending
                        ? "Redirecting..."
                        : "Add Information to Stripe"}
                    </button>
                  )}
                  {onboardingError && (
                    <p className="error">
                      Something went wrong with onboarding! - {error}
                    </p>
                  )}
                </div>
              </div>
            </div>
          </div>
        )}

        {stripeOnboardingComplete && (
          <div className="stripeOnboardingContainer">
            <div className="stripeOnboardingContainerHeader">
              <h3>Accept Payments</h3>
              <div className="stripeOnboardingContainerLine"></div>
            </div>
            <div className="stripeOnboardingContainerBody">
              <div className="stripeOnboardingContainerBodyWarning">
                <div className="stripeOnboardingContainerBodyWarningSpanComplete">
                  {/* <p>!</p> */}
                  <p>{stripeOnboardingComplete ? "✓" : "!"}</p>
                </div>
                <span>Payment Processing Active</span>
              </div>
              <div className="stripeOnboardingContainerBodyText">
                <h3>
                  You are All Set Up! You Can Accept Payments Through The Site.
                </h3>
                <br />
                <p>
                  Your customers can still request to book through the site, and
                  or pay through the site + all other functionality.
                </p>
                <br />
                <p>
                  You Can Configure If You Want Payments On/Off Per Vehicle.
                  Please Go To The Vehicle Tab and Edit Vehicle.
                </p>
                <div className="stripeOnboardingContainerBodyConfirm">
                  🔒 Secure Verification Powered By{" "}
                  <FaStripe className="stripeIcon" />
                  {!connectedAccountId && (
                    <button
                      onClick={handleOnboardStripe}
                      disabled={accountCreatePending}
                      className="stripeOnboardingButton"
                    >
                      {accountCreatePending
                        ? "Creating account..."
                        : "Start Stripe Onboarding"}
                    </button>
                  )}
                  {connectedAccountId && (
                    <button
                      onClick={handleAddInformation}
                      disabled={accountLinkCreatePending}
                      className="stripeOnboardingButton"
                    >
                      {accountLinkCreatePending
                        ? "Redirecting..."
                        : "Manage Connect Details"}
                    </button>
                  )}
                  {onboardingError && (
                    <p className="error">
                      Something went wrong with onboarding! - {error}
                    </p>
                  )}
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
    );
  }

  return content;
};

export default DashboardProfile;

import { useEffect, useState } from "react";
import { useGetAllBookingsWithRejectedQuery } from "../bookings/bookingsApiSlice";
import useAuth from "../../hooks/useAuth";
import { Link } from "react-router-dom";
import { FaRandom, FaSearch } from "react-icons/fa";
import { HashLoader } from "react-spinners";

function calculateDuration(startDate, endDate) {
  const start = new Date(startDate);
  const end = new Date(endDate);
  const differenceInDays = Math.round((end - start) / (1000 * 60 * 60 * 24));
  return differenceInDays + " days";
}

function wrapOrderNumber(orderNumber) {
  if (orderNumber.length > 15) {
    return (
      <span>
        {orderNumber.slice(0, 15)}
        <br />
        {orderNumber.slice(15)}
      </span>
    );
  } else {
    return orderNumber;
  }
}

const DashboardMyRequestsCustomer = () => {
  const { email } = useAuth();

  const [searchQuery, setSearchQuery] = useState("");
  const [filteredBookings, setFilteredBookings] = useState([]);
  const [filterDropdownOpen, setFilterDropdownOpen] = useState(false);
  const [selectedFilter, setSelectedFilter] = useState("newest"); // Default to newest

  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 15;
  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;

  const handleSearchChange = (event) => {
    setSearchQuery(event.target.value);
  };

  // Fetch bookings using the hook directly
  const {
    data: bookings,
    isLoading,
    isSuccess,
    isError,
    error,
  } = useGetAllBookingsWithRejectedQuery(undefined, {
    pollingInterval: 60000,
    refetchOnFocus: true,
    refetchOnMountOrArgChange: true,
  });

  useEffect(() => {
    if (isSuccess && email) {
      const filtered = Object.values(bookings.entities).filter(
        (booking) => booking?.customer?.email === email
      );
      setFilteredBookings(filtered);
    }
  }, [bookings, isSuccess, email]);

  const totalPages = Math.ceil(filteredBookings.length / itemsPerPage);

  let content;

  if (isLoading) content = <HashLoader color="#36d7b7" />;
  if (isError) content = <p className="errmsg">{error?.data?.message}</p>;

  if (isSuccess) {
    content = (
      <div className="myOrdersCustomer">
        <div className="myOrdersSearchbar">
          <div className="myOrdersSearchbarDiv">
            <div className="myOrdersSearchbarIcon">
              <FaSearch />
            </div>
            <div className="myOrdersSearchbarInputField">
              <input
                type="text"
                placeholder="Search"
                value={searchQuery}
                onChange={handleSearchChange}
              />
            </div>
          </div>
        </div>
        <div className="progressSystemExplainedCustomer">
          <h3>How Does The Booking Request Sysytem Work?</h3>
          <p>
            Every booking has a booking progress which can either be "Rejected",
            "To Be Confirmed", "Accepted Payment Not Received", "Accepted
            Payment Received".
          </p>
          <br />
          <p>
            If You Have Requested To Book, The Dealership Will Be In Contact You
            to Confirm Details and Payment.
          </p>
          <br />
          <p>
            Once the dealership decides if they will accept the request, then
            the booking will show up on the my orders page.
          </p>
          <br />
          <p>
            Once the dealership decides if they will accept the request, then
            the booking will show up on the my orders page.
          </p>
        </div>
        <div className="myOrdersH2">
          <h2>My Requests</h2>
        </div>
        <div className="myOrdersMain">
          <div className="myOrdersFilterAndSearch">
            <div className="filterBtn">
              <button
                onClick={() => setFilterDropdownOpen(!filterDropdownOpen)}
              >
                <FaRandom className="filterIconMyOrdersPageCustomer" />
                Filter
              </button>
              {filterDropdownOpen && (
                <ul
                  className={`filterDropdown ${filterDropdownOpen && "show"}`}
                >
                  <li onClick={() => setSelectedFilter("newest")}>Newest</li>
                  <li onClick={() => setSelectedFilter("oldest")}>Oldest</li>
                  <li onClick={() => setSelectedFilter("mostExpensive")}>
                    Price High-Low
                  </li>
                  <li onClick={() => setSelectedFilter("leastExpensive")}>
                    Price Low-High
                  </li>
                </ul>
              )}
            </div>
            <div className="searchBarOrdersPage">
              <FaSearch className="searchIconMyOrdersPageCustomer" />
              <input
                type="text"
                placeholder="Search"
                value={searchQuery}
                onChange={handleSearchChange}
              />
            </div>
          </div>
          <div className="myOrdersData">
            <table className="myOrdersTable">
              <thead>
                <tr>
                  <th>Order</th>
                  <th>Date</th>
                  <th>Item</th>
                  <th>Duration</th>
                  <th>Customer</th>
                  {/* <th>Pick Up</th>
                  <th>Dropoff</th> */}
                  <th>Booking Progress</th>
                  <th>Total</th>
                </tr>
              </thead>
              <tbody>
                {filteredBookings
                  .filter((booking) => {
                    const searchTerms = searchQuery.toLowerCase().split(" ");
                    // Case-insensitive search
                    return (
                      searchTerms.length === 0 ||
                      searchTerms.some(
                        (term) =>
                          booking.id.toLowerCase().includes(term) ||
                          booking.dateOfBooking.toLowerCase().includes(term) ||
                          booking.vehicleDetails.make
                            .toLowerCase()
                            .includes(term) ||
                          booking.vehicleDetails.model
                            .toLowerCase()
                            .includes(term) ||
                          booking.vehicleDetails.modelVariant
                            .toLowerCase()
                            .includes(term) ||
                          booking.customer.firstname
                            .toLowerCase()
                            .includes(term) || // Use 'term' here
                          booking.customer.lastname
                            .toLowerCase()
                            .includes(term) || // Use 'term' here
                          booking.pickUpLocation.toLowerCase().includes(term) || // Use 'term' here
                          booking.dropOffLocation
                            .toLowerCase()
                            .includes(term) || // Use 'term' here
                          booking.totalPrice.toString().includes(term) ||
                          booking.bookingProgress?.includes(term) // Use 'term' here
                      )
                    );
                  })
                  .sort((a, b) => {
                    switch (selectedFilter) {
                      case "newest":
                        return (
                          new Date(b.dateOfBooking) - new Date(a.dateOfBooking)
                        );
                      case "oldest":
                        return (
                          new Date(a.dateOfBooking) - new Date(b.dateOfBooking)
                        );
                      case "mostExpensive":
                        return b.totalPrice - a.totalPrice;
                      case "leastExpensive":
                        return a.totalPrice - b.totalPrice;
                      default:
                        return 0;
                    }
                  })
                  .slice(indexOfFirstItem, indexOfLastItem)
                  .map((booking) => (
                    <tr key={booking.id}>
                      <td className="orderNumberCell">
                        <Link to={`/customerdashboard/myOrders/${booking.id}`}>
                          {wrapOrderNumber(booking.id)}
                        </Link>
                      </td>
                      <td className="dashboardCustomerOrdersLinks">
                        <Link to={`/customerdashboard/myOrders/${booking.id}`}>
                          {booking.dateOfBooking}
                        </Link>
                      </td>
                      <td className="dashboardCustomerOrdersLinks">
                        <Link to={`/customerdashboard/myOrders/${booking.id}`}>
                          {booking.vehicleDetails.make}{" "}
                          {booking.vehicleDetails.model}{" "}
                          {booking.vehicleDetails.modelVariant}{" "}
                        </Link>
                      </td>
                      <td className="dashboardCustomerOrdersLinks">
                        <Link to={`/customerdashboard/myOrders/${booking.id}`}>
                          {booking.startDate.slice(8, 10)}-
                          {booking.startDate.slice(5, 7)}-
                          {booking.startDate.slice(0, 4)} -
                          {booking.endDate.slice(8, 10)}-
                          {booking.endDate.slice(5, 7)} -
                          {booking.endDate.slice(0, 4)}
                          {" (" +
                            calculateDuration(
                              booking.startDate,
                              booking.endDate
                            ) +
                            ")"}
                        </Link>
                      </td>
                      <td className="dashboardCustomerOrdersLinks">
                        <Link to={`/customerdashboard/myOrders/${booking.id}`}>
                          {booking.customer.firstname}{" "}
                          {booking.customer.lastname}
                        </Link>
                      </td>
                      {/* <td className="dashboardCustomerOrdersLinks">
                        <Link to={`/customerdashboard/myOrders/${booking.id}`}>
                          {booking.pickUpLocation}
                        </Link>
                      </td>
                      <td className="dashboardCustomerOrdersLinks">
                        <Link to={`/customerdashboard/myOrders/${booking.id}`}>
                          {booking.dropOffLocation}
                        </Link>
                      </td> */}

                      <td className="dashboardCustomerOrdersLinks">
                        <Link to={`/customerdashboard/myOrders/${booking.id}`}>
                          {booking.bookingProgress}
                        </Link>
                      </td>

                      <td className="dashboardCustomerOrdersLinks">
                        <Link to={`/customerdashboard/myOrders/${booking.id}`}>
                          £{booking.totalPrice}
                        </Link>
                      </td>
                    </tr>
                  ))}
              </tbody>
            </table>
            <div className="pagination">
              {Array.from({ length: totalPages }, (_, index) => (
                <button
                  key={index + 1}
                  onClick={() => setCurrentPage(index + 1)}
                  className={index + 1 === currentPage ? "active" : ""}
                >
                  {index + 1}
                </button>
              ))}
            </div>
          </div>
        </div>
      </div>
    );
  }

  return content;
};

export default DashboardMyRequestsCustomer;

import React from "react";

const TermsAndConditions = () => {
  return (
    <div className="termsAndConditions">
      <h3>Terms And Conditions</h3>
      <p>Last Updated - 14/10/24</p>
      <h2>1. Introduction</h2>
      <p>
        These Terms and Conditions govern the use of IMAS Cars, owned and
        operated by IMAS Cars, a business in the United Kingdom. By signing up
        or using the Platform, you agree to comply with these Terms and
        Conditions. If you do not agree, please do not use the Platform.
      </p>
      <h2>2. Eligibility</h2>
      <p>
        You must be at least 18 years old and legally capable of entering into
        contracts under UK law to use the Platform. Dealerships must be
        registered businesses legally authorised to offer car rental services.
        Failure to comply will result in account termination.
      </p>
      <h2>3. Account Registration</h2>
      <p>
        Both Dealerships and Customers must create an account to use the
        Platform. You are responsible for maintaining the confidentiality of
        your account details and for any activity under your account.
      </p>
      <h2>4. Subscription Plans for Dealerships</h2>
      <p>
        We offer three subscription plans for Dealerships: Monthly Plan, 6-Month
        Plan or Yearly Plan
        <br />
        4.1 Free Trial All subscription plans include a 5-day free trial. During
        this period, you can cancel at any time without being charged. After the
        free trial, payment for the selected plan will be processed. <br />
        4.2 Cancellation Policy You may cancel your subscription at any time;
        however, you will still have access to the Platform until the end of the
        billing period for which you have already paid. No refunds will be
        provided for unused time after cancellation.
        <br /> 4.3 Renewals Your subscription will automatically renew unless
        canceled before the end of the billing period. We will notify you before
        the renewal of any changes to pricing.
      </p>
      <h2>5. Customer Bookings</h2>
      <p>
        Customers may use the Platform to book vehicles directly with
        Dealerships. IMAS Cars does not facilitate payments for these bookings.
        Once a booking is made, the Dealership is solely responsible for
        communicating with the Customer regarding payment and any additional
        terms related to the booking. IMAS Cars is not liable for any payments
        you make to dealerships.
      </p>
      <br />
      <p>
        Customers may use the Platform to book and pay for vehicles directly
        with Dealerships. All payments are processed through our secure payment
        provider. While IMAS Cars facilitates the payment process, we act solely
        as a payment processor and are not responsible for: - Any discrepancies
        between the booking details and actual service provided - Refunds or
        disputes, which must be handled directly between the Customer and
        Dealership - Any cash or external payments made outside our Platform By
        using our payment system, you agree to: - Provide accurate and valid
        payment information - Accept our payment processor's terms of service -
        Handle any chargebacks or disputes in good faith All transactions are
        subject to verification and fraud detection checks.
      </p>
      <h2>6. Payment and Billing (Dealerships Only)</h2>
      <p>
        By signing up for a subscription, you authorise us to charge the payment
        method provided for the applicable subscription plan. All prices are
        listed in GBP (£) and are exclusive of any taxes unless stated
        otherwise.
      </p>
      <br />
      <p>
        6.1 Platform Fees IMAS Cars May charg a processing fee (subject to
        chosen plan) for each successful transaction. This fee will be clearly
        displayed before the transaction is completed.
        <br />
        6.2 Dealership Payouts - Payments will be processed to
        dealership-connected accounts within stripes stated business days -
        Dealerships must maintain valid banking information for payouts - IMAS
        Cars reserves the right to hold payments for fraud investigation
        <br />
      </p>
      <p>6.3 Refund Policy</p>

      <p>
        Processing Refunds: All refunds must be processed through our platform.
        We utilize Stripe as our payment processor to facilitate these
        transactions.
      </p>

      <p>
        Dealership Responsibilities: Each dealership is responsible for
        establishing and clearly communicating its own refund policies to
        customers. These policies should comply with applicable laws and
        regulations.
      </p>

      <p>
        Stripe's Role: Stripe facilitates the processing of refunds in
        accordance with the dealership's established policies. Please note that
        while Stripe submits refund requests to the customer's bank or card
        issuer immediately, it may take 5-10 business days for the refund to
        appear in the customer's account, depending on the bank's processing
        times.{" "}
        <a
          href="https://support.stripe.com/embedded-connect/questions/customer-refund-processing-time?locale=en-GB&utm_source=chatgpt.com"
          target="_blank"
        >
          Learn more
        </a>
        .
      </p>

      <p>
        Fees: Stripe's processing fees from the original transaction are not
        returned when a payment is refunded. This means that the fees incurred
        during the initial transaction will not be reimbursed to the dealership
        upon issuing a refund.{" "}
        <a
          href="https://support.stripe.com/questions/understanding-fees-for-refunded-payments?utm_source=chatgpt.com"
          target="_blank"
        >
          Learn more
        </a>
        .
      </p>

      <p>
        Failed Refunds: In rare cases, a refund may fail due to issues such as a
        closed bank account or problems with the customer's card. If this
        occurs, the refunded amount will be returned to our Stripe account
        balance, and we will arrange an alternative method to provide the refund
        to the customer.{" "}
        <a href="https://docs.stripe.com/refunds" target="_blank">
          Learn more
        </a>
        .
      </p>

      <p>
        Cancellation of Refunds: Once initiated, refunds cannot be canceled. If
        a refund needs to be reversed, a new charge must be created for the
        customer in the amount of the refund.{" "}
        <a
          href="https://support.stripe.com/embedded-connect/questions/canceling-a-refund"
          target="_blank"
        >
          Learn more
        </a>
        .
      </p>

      <p>
        By adhering to these guidelines, we aim to ensure a transparent and
        efficient refund process for both dealerships and customers.
      </p>

      <h2>7. No Liability for Transactions or Services</h2>
      <p>
        IMAS Cars acts solely as an intermediary platform to facilitate
        communication and bookings between Customers and Dealerships. We are not
        liable for: Any issues with the vehicles, including but not limited to
        their condition, availability, or legality. Any disputes between
        Customers and Dealerships, including payment issues, cancellations, or
        booking modifications. Any legal or regulatory compliance issues related
        to vehicle rentals or business operations conducted by Dealerships. All
        dealings and disputes between Customers and Dealerships are the sole
        responsibility of the parties involved.
      </p>
      <br />
      <p>
        We are not liable for: - Payment processing issues by third-party
        providers - Financial losses due to incorrect banking information -
        Unauthorized transactions from compromised user accounts - Any fees or
        charges imposed by users' banks or financial institutions
      </p>
      <h2>8. Limitation of Liability</h2>
      <p>
        To the fullest extent permitted by England and Wales law, IMAS Cars and
        its officers, employees, and agents are not liable for any direct,
        indirect, incidental, special, or consequential damages, including but
        not limited to loss of profits, revenue, or data, arising from the use
        or inability to use the Platform.
      </p>
      <h2>9. Indemnification</h2>
      <p>
        You agree to indemnify and hold harmless IMAS Cars, its officers,
        employees, and agents from any claims, losses, liabilities, or expenses
        (including legal fees) arising from your use of the Platform, violation
        of these Terms and Conditions, or breach of any applicable England and
        Wales law.
      </p>
      <h2>10. Data Protection and Privacy</h2>
      <p>
        Your privacy is important to us. By using the Platform, you consent to
        the collection and use of your data in accordance with our Privacy
        Policy, which complies with the General Data Protection Regulation
        (GDPR) and UK Data Protection laws.
      </p>
      <h2>11. Intellectual Property</h2>
      <p>
        All content on the Platform, including but not limited to text, images,
        logos, and software, is the intellectual property of IMAS Cars or
        licensed to us. You are prohibited from using our content without
        express written permission.
      </p>
      <h2>12. Modifications to the Service</h2>
      <p>
        We reserve the right to modify, suspend, or discontinue the Platform, in
        whole or in part, at any time without notice. We will not be liable to
        you or any third party for any modifications or interruptions to the
        Platform.
      </p>
      <h2>13. Termination</h2>
      <p>
        We may terminate or suspend your access to the Platform at our
        discretion if you violate these Terms and Conditions or any applicable
        laws. Upon termination, all rights and licenses granted to you will
        cease immediately.
      </p>
      <h2>14. Governing Law and Jurisdiction</h2>
      <p>
        These Terms and Conditions are governed by the laws of England and
        Wales. Any disputes arising under these Terms will be subject to the
        exclusive jurisdiction of the courts of England and Wales.
      </p>
      <h2>15. Changes to These Terms</h2>
      <p>
        We may update these Terms and Conditions from time to time. We will
        notify you of any significant changes by posting the revised terms on
        the Platform. Your continued use of the Platform following the changes
        constitutes acceptance of the revised terms.
      </p>
      <h2>16. Financial Disputes</h2>
      <p>
        In case of financial disputes: - IMAS Cars may act as a mediator but is
        not obligated to do so - We reserve the right to freeze accounts during
        dispute investigations - We may share relevant transaction information
        with law enforcement or legal authorities if required - Users agree to
        attempt resolution through our dispute process before pursuing legal
        action
      </p>

      <h2>17. Platform Security</h2>
      <p>
        Users agree to: - Not attempt to bypass our payment system - Report any
        security vulnerabilities immediately - Not use the Platform for money
        laundering or other financial crimes - Maintain secure access to their
        payment information
      </p>

      <h2>18. Service Fees and Commissions</h2>
      <p>
        - IMAS Cars charges service fees for successful transactions - Fee
        structures may vary based on subscription plans - All fees are
        non-refundable unless required by law - We reserve the right to modify
        fee structures with notice
      </p>
      <h2>19. Payment Disputes and Fraud Prevention</h2>
      <p>
        19.1 Service Fees and Commissions - IMAS Cars charges service fees for
        successful transactions - Fee structures may vary based on subscription
        plans - All fees are non-refundable unless required by law - We reserve
        the right to modify fee structures with notice <br />
        19.2 Fraud Prevention - IMAS Cars employs fraud detection systems to
        protect all parties - Suspicious booking patterns or payment activities
        may trigger automatic reviews - We reserve the right to cancel bookings
        that trigger our fraud detection systems - Users agree to cooperate with
        any fraud investigations
      </p>

      <h2>20. Booking Payments and Refunds</h2>
      <p>
        20.1 Payment Finality - Once a vehicle rental period has commenced, the
        booking is considered fulfilled and is non-refundable - Any refund
        requests for completed rentals will be automatically rejected -
        Customers acknowledge that chargebacks or payment disputes for completed
        rentals constitute a breach of these terms <br />
        20.2 Booking Responsibility - By making a booking and payment, customers
        acknowledge they are entering into a binding agreement - Customers agree
        not to dispute charges for rentals that have already taken place - Any
        attempt to fraudulently obtain refunds through chargebacks or payment
        disputes may result in: a) Immediate account termination b) Legal action
        to recover funds c) Reporting to relevant authorities d) Addition to our
        platform's blacklist <br />
        20.3 Evidence of Service - Dealerships are advised to maintain proof of
        vehicle pickup and return - Customers acknowledge that such evidence may
        be used to contest fraudulent refund attempts - IMAS Cars reserves the
        right to share such evidence with payment providers and authorities in
        case of disputes <br />
        20.4 Dispute Resolution - All payment disputes must be raised before the
        rental period begins - Once a vehicle has been collected, the service is
        considered accepted - IMAS Cars maintains transaction records and rental
        confirmation logs which may be used as evidence in case of disputes{" "}
        <br />
        20.5 Customer Acknowledgment - By proceeding with a booking and payment,
        customers explicitly acknowledge: a) They understand the non-refundable
        nature of commenced rentals b) They accept that payment disputes for
        completed rentals violate these terms c) They are responsible for all
        authorized charges on their account
      </p>

      <h2>21. Payment Processing and Security</h2>
      <p>
        21.1 Payment Processing - All payments must be made through our
        platform's secure payment system - External payments or cash
        transactions are not protected by our terms - Users must provide
        accurate and valid payment information <br />
        21.2 Chargeback Consequences - Filing a chargeback for a completed
        rental will result in: a) Immediate account suspension b) Forfeiture of
        ability to use the platform in future c) Potential legal proceedings to
        recover funds and costs d) Reporting to credit reference agencies <br />
        21.3 Payment Verification - IMAS Cars may require additional
        verification for high-value bookings - We reserve the right to request
        proof of identity before processing payments - Failure to provide
        requested verification may result in booking cancellation
      </p>
      <h2>22. Contact Us</h2>
      <p>
        If you have any questions about these Terms and Conditions, please
        contact us at IMAS-Cars@outlook.com.
      </p>
    </div>
  );
};

export default TermsAndConditions;
